import { createSlice } from "@reduxjs/toolkit";

// Initial state for the dashboard slice
const initialState = {
    dashboardData: {}
}

export const dashboardReducer = createSlice({
    name: 'dashboardReducer',
    initialState,
    reducers: {
        /**
         * Get dashboard data and update the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Object} action.payload - The dashboard data
         */
        getDashboardData: (state, action) => {
            state.dashboardData = action.payload;
        },
    }
});

// Export actions to be used in components
export const { getDashboardData } = dashboardReducer.actions;
// Export reducer to be used in the store
export default dashboardReducer.reducer;