import accountApi from "../../api/accountApi";
import { fetchRoles, createRoles, editRole } from "../reducer/roleReducer";
import { displayAlert } from "../reducer/alertReducer";

/**
 * Fetch all roles
 * @function
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchAllRoles() {
    return async (dispatch) => {
        accountApi.get(`roles`).then(async (response) => {
            if (response.status === 'success') {
                dispatch(fetchRoles(response.payload))
            }
        })
    }
};

/**
 * Create a new role
 * @function
 * @param {Object} data - The data for creating a new role
 * @param {Object} state - The current state of the component
 * @param {Function} setState - The function to update the state of the component
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function createAllRoles(data, state, setState) {
    return async (dispatch) => {
        accountApi.post(`roles`, data).then(async (response) => {
            if (response.status === 'success') {
                dispatch(createRoles(response.payload))
                setState({ ...state, isDialogueOpen: false, existPermission: [] })
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "success", alertMessage: "New role has been created successfully" }));
            }
            else {
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "error", alertMessage: "Code already Exists" }))
            }
        })
    }
};

/**
 * Update an existing role
 * @function
 * @param {Object} data - The data for updating the role, including the ID and new values
 * @param {Object} state - The current state of the component
 * @param {Function} setState - The function to update the state of the component
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function updateRole(data, state, setState) {
    return async (dispatch) => {
        accountApi.put(`roles/${data.id}`, data).then(async (response) => {
            if (response.status === 'success') {
                dispatch(editRole(response.payload))
                setState({ ...state, isDialogueOpen: false })
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "info", alertMessage: "Existing role has been updated successfully" }));
            }
        })
    }
}