import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MuiAppBar from '@mui/material/AppBar';
import {
    Toolbar, Typography, IconButton, Box, Tooltip,
    Avatar, Menu, MenuItem, Divider, DialogContent, Dialog, DialogActions,
    DialogTitle, Button, Slide, TextField, Grid
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import TvsLogo from '../../../../assets/logo_tvs.webp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { changePassword } from '../../../../store/actions/userAction';
import { customStyles } from '../../../../helpers/customStyles';
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

Topbar.propTypes = {
    isOpen: PropTypes.bool,
    handleDrawerOpen: PropTypes.func,
};

function Topbar(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector((state) => state.session.user);

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [invalidPasswordError, setInvalidPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [invalidConfirmPasswordError, setInvalidConfirmPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [windowWidth, setWindow] = useState(null);

    const open = Boolean(anchorEl);

    useEffect(() => {
        let windowSize = window.screen.width;
        setWindow(windowSize)
    }, [windowWidth]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        navigate('/', { replace: true })
        localStorage.clear();
    };

    const handleChangePassword = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setPassword("")
        setPasswordError(false)
        setInvalidPasswordError(false)
        setConfirmPassword("")
        setConfirmPasswordError(false)
        setInvalidConfirmPasswordError(false)
    };

    const passwordValidation = (params) => {
        let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(\S){8,}$/;
        return re.test(params);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError(false);
        setInvalidPasswordError(false);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(false);
        setInvalidConfirmPasswordError(false);
    };

    const submitAction = () => {
        let isError = false;
        setPasswordError(false);
        setInvalidPasswordError(false);
        setConfirmPasswordError(false);
        setInvalidConfirmPasswordError(false);

        if (password === "" || password === null) {
            setPasswordError(true);
            isError = true;
        } else if (!passwordValidation(password)) {
            setInvalidPasswordError(true);
            isError = true;
        }
        if (confirmPassword === "" || confirmPassword === null) {
            setConfirmPasswordError(true);
            isError = true;
        } else if (confirmPassword !== password) {
            setInvalidConfirmPasswordError(true);
            isError = true;
        }

        if (!isError) {
            let data = {};
            data.user_name = !!userDetail && !!userDetail.user && userDetail.user.user_name
            data.password = password;
            dispatch(changePassword(data, handleDialogClose))
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Box sx={styles.boxStyle}>
            {windowWidth < 600 ?
                <div style={styles.topDiv}>
                    <img src={TvsLogo} alt='logo' height={70} width={70} style={{ marginRight: 5 }} />
                    <Typography style={styles.headerMobileText} >SUNDARAM BRAKE LININGS LIMITED</Typography>
                </div>
                :
                <div style={styles.topDiv}>
                    <img src={TvsLogo} alt='logo' height={180} width={180} style={{ marginRight: 10 }} />
                    <Typography style={styles.headerText} >SUNDARAM BRAKE LININGS LIMITED</Typography>
                </div>
            }
            <AppBar position='sticky' open={props.isOpen}>
                <Toolbar sx={styles.toolBarStyle}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => props.handleDrawerOpen()}
                        edge="start"
                        sx={{ mr: 2, ...(props.isOpen && { zIndex: 1800 }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={styles.regularText}>DEFCON</Typography>
                    <Box style={{ flexGrow: 1, textAlign: 'right', marginRight: '20px' }}>
                        <Typography style={styles.regularText} noWrap sx={{ flexGrow: 0 }} component="div">
                            {!!userDetail && !!userDetail.user && userDetail.user.userProfile.first_name} {!!userDetail && !!userDetail.user && userDetail.user.userProfile.last_name}<span></span>
                        </Typography>
                        <Typography style={styles.regularText}>
                            {!!userDetail && !!userDetail.role && userDetail.role.name}
                        </Typography>
                    </Box>
                    <Tooltip title="Account settings">
                        <IconButton
                            onClick={handleClick}
                            size="medium"
                            sx={{ ml: 2 }}
                        >
                            <Avatar sx={{ width: 32, height: 32 }}>{!!userDetail && !!userDetail.role && userDetail.role.name.charAt(0).toUpperCase()}</Avatar>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        // PaperProps={{
                        //     elevation: 0,
                        //     sx: {
                        //         overflow: 'visible',
                        //         filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        //         mt: 1.5,
                        //         '& .MuiAvatar-root': {
                        //             width: 32,
                        //             height: 32,
                        //             ml: -0.5,
                        //             mr: 1,
                        //         },
                        //         '&::before': {
                        //             content: '""',
                        //             display: 'block',
                        //             position: 'absolute',
                        //             top: 0,
                        //             right: 14,
                        //             width: 10,
                        //             height: 10,
                        //             bgcolor: 'background.paper',
                        //             transform: 'translateY(-50%) rotate(45deg)',
                        //             zIndex: 0,
                        //         },
                        //     },
                        // }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem
                            onClick={handleChangePassword}
                        >
                            <Avatar title="Log out" sx={{ bgcolor: "#fff" }}>
                                <IconButton color={'info'} size={'medium'} onClick={handleChangePassword}><LockResetIcon fontSize="inherit" /></IconButton>
                            </Avatar >
                            <Typography style={customStyles.regularText}>Change Password</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={handleLogout}
                        >
                            <Avatar title="Log out" sx={{ bgcolor: "#fff" }}>
                                <IconButton color={'error'} size={'medium'} onClick={handleLogout}><ExitToAppIcon fontSize="inherit" /></IconButton>
                            </Avatar >
                            <Typography style={customStyles.regularText}>Logout</Typography>
                        </MenuItem>
                        <Divider />
                    </Menu>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        fullWidth
                        maxWidth="sm"
                        TransitionComponent={Transition}
                    >
                        <DialogTitle style={{ display: 'flex' }} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography style={customStyles.boldText}>Change Password</Typography>
                            <IconButton onClick={() => handleDialogClose()} style={{ color: '#000' }}>
                                <DisabledByDefaultRoundedIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Grid item xs={5}><Typography style={customStyles.textStyle}>UserName</Typography></Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={6}>
                                        <Typography style={customStyles.textStyle}>
                                            {!!userDetail && !!userDetail.user && `${userDetail.user.userProfile.first_name} ${userDetail.user.userProfile.last_name}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Grid item xs={5}> <Typography style={customStyles.textStyle}>Role</Typography></Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={6}>
                                        <Typography style={customStyles.textStyle}>
                                            {!!userDetail && !!userDetail.role && userDetail.role.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Grid item xs={5}> <Typography style={customStyles.textStyle}>New Password:</Typography></Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-password-input"
                                            label="New Password"
                                            type={showPassword ? 'text' : 'password'}
                                            autoComplete="current-password"
                                            size='small'
                                            value={password}
                                            onChange={handlePasswordChange}
                                            error={passwordError || invalidPasswordError}
                                            helperText={passwordError ? "Please enter password" : invalidPasswordError ? "Please enter a valid password with at least 8 characters, including a number, an uppercase, and a lowercase letter" : ""}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={handleClickShowPassword}>
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Grid item xs={5}> <Typography style={customStyles.textStyle}>Confirm Password:</Typography></Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-confirm-password-input"
                                            label="Confirm Password"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            autoComplete="current-password"
                                            size='small'
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            error={confirmPasswordError || invalidConfirmPasswordError}
                                            helperText={confirmPasswordError ? "Please enter confirm password" : invalidConfirmPasswordError ? "Passwords do not match" : ""}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={handleClickShowConfirmPassword}>
                                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus variant='contained' color='error' style={customStyles.cancelButtonText} onClick={handleDialogClose}>Cancel</Button>
                            <Button autoFocus variant="contained" color="primary" style={customStyles.buttonText} sx={{ '&.MuiButton-root:hover': { bgcolor: "#2F7FFF" } }} onClick={submitAction}>Submit</Button>
                        </DialogActions>
                    </Dialog>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

const styles = {
    boldText: {
        fontFamily: "Open-Sans-Bold",
        fontSize: "18px",
        fontWeight: "950"
    },
    regularText: {
        fontFamily: "Open-Sans-Regular",
        fontSize: "16px",
        fontWeight: "600",

    },
    headerText: {
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: "28px",
        fontWeight: "600",
        color: '#0D4576',
        flexGrow: 9
    },
    headerMobileText: {
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: "1em",
        fontWeight: "600",
        color: '#0D4576',
        flexGrow: 9
    },
    boxStyle: {
        flexGrow: 1,
        position: 'fixed',
        minWidth: '100%',
        zIndex: 1200
    },
    topDiv: {
        height: 80,
        display: 'flex',
        alignItems: ' center',
        backgroundColor: '#FFF'
    },
    toolBarStyle: {
        backgroundImage: 'linear-gradient(89.8deg, #2E8BC0 30.3%, #09225C 101.4%);'
    },
}

export default Topbar;
