import { createSlice } from "@reduxjs/toolkit";

// Initial state for the alert slice
const initialState = {
    openAlert: false,
    alertSeverity: '',
    alertMessage: ''
}

export const alertReducer = createSlice({
    name: 'alert',
    initialState,
    reducers: {
      /**
      * Set the alert state
      * @param {Object} state - The current state
      * @param {Object} action - The action object containing payload
      * @param {boolean} action.payload.openAlert - Whether the alert should be open
      * @param {string} action.payload.alertSeverity - The severity of the alert
      * @param {string} action.payload.alertMessage - The message to display
      */
        displayAlert: (state, action) => {
            state.openAlert = action.payload.openAlert;
            state.alertSeverity = action.payload.alertSeverity;
            state.alertMessage = action.payload.alertMessage;
        },
    }
});

// Export actions to be used in components
export const { displayAlert } = alertReducer.actions;
// Export reducer to be used in the store
export default alertReducer.reducer