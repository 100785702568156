import { createSlice } from "@reduxjs/toolkit";

// Initial state for the generate code slice
const initialState = {
    generateCode: []
}

export const generateCodeReducer = createSlice({
    name: 'generateCode',
    initialState,
    reducers: {
        /**
         * Fetch generate code and update the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Array} action.payload - The list of generated codes
         */
        fetchGenerateCode: (state, action) => {
            state.generateCode = action.payload;
        },
        /**
        * Create new generate code and update the state
        * @param {Object} state - The current state
        * @param {Object} action - The action object containing payload
        * @param {Array} action.payload - The list of generated codes after creation
        */
        createGenerateCode: (state, action) => {
            state.generateCode = action.payload;
        },
        /**
         * Edit generate code and update the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Object} action.payload - The edited generated code
         */
        editGenerateCode: (state, action) => {
            state.generateCode = state.generateCode.map((item) => item.id === action.payload.id ? action.payload : item)
        }
    }
});

// Export actions to be used in components
export const { fetchGenerateCode, createGenerateCode, editGenerateCode } = generateCodeReducer.actions;
// Export reducer to be used in the store
export default generateCodeReducer.reducer;