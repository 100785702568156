// Exporting an array of sidebar items
export const sideBar = [
  {
    screen: 'Dashboard',
    path: '/dashboard'
  },
  {
    screen: 'Users',
    path: '/users'
  },
  {
    screen: 'Test Drive',
    path: '/test-drives'
  },
  {
    screen: 'Screens',
    path: '/screens'
  },
  {
    screen: 'Roles',
    path: '/roles'
  },
  {
    screen: 'Referral',
    path: '/generate'
  },
];