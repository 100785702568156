import React, { useState, useEffect, useMemo,useCallback} from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment, FormHelperText, Tooltip, InputLabel, Chip, FormControlLabel, Grid, FormControl, Radio, RadioGroup, Button, Select, MenuItem, Typography, Box } from '@mui/material';
import { addNewUser, editUser, fetchActiveUsers } from '../../store/actions/userAction';
import { fetchAllRoles } from "../../store/actions/roleAction";
import { useDispatch, useSelector } from "react-redux";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { customStyles } from '../../helpers/customStyles';
import CustomPermission from '../../components/CustomPermission';
import palette from '../../theme/palette';
import { fontFamily } from '../../helpers/customStyles';
import { countries } from '../../helpers/countries'
import Autocomplete from "@mui/material/Autocomplete";

const styles = {
    tableBodyCell: {
        fontFamily: fontFamily.regularFont,
        fontSize: '14px',
        color: palette.listTable.cellColor
    },
    tableHeadCell: {
        textAlign: "center",
        backgroundColor: palette.primary.main,
        color: palette.secondary.main,
        fontFamily: fontFamily.boldFont
    },
    formTextStyle: {
        color: palette.error.main,
        marginLeft: "5px",
    },
    dobTextField: {
        '& .MuiInputBase-input': {
            fontFamily: customStyles.regularText.fontFamily,
        },
        '&.Mui-disabled': {
            border: 'none',
        },
        backgroundColor: '#FFF',
    },
    cancelButton: {
        position: 'absolute',
        right: 8,
        top: 8,
        color: 'common.black'
    },
    statusField: {
        color: 'common.black',
        marginRight: '20px'
    }
}

const ActiveUser = () => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.activeUsers);
    // let userData = useSelector((state) => state.session.user);
    // let permission = userData?.role?.permission.find((item) => item.screen == "Users");
    const roles = useSelector((state) => state.role.roles)
    const [state, setState] = useState({
        id: "",
        selectedRoleId: "",
        selectedRoleIdError: false,
        firstName: "",
        firstNameError: false,
        lastName: "",
        lastNameError: false,
        addressLine1: "",
        addressLine1Error: false,
        phoneNumber: "",
        phoneNumberError: false,
        addressLine2: "",
        addressLine2Error: false,
        city: "",
        cityError: false,
        userState: "",
        userStateError: false,
        email: "",
        emailError: false,
        pincode: "",
        pincodeError: false,
        status: "ACTIVE",
        mode: "ADD",
        isDialogOpen: false,
        dateOfBirth: null,
        selectedCountryCode: "91",
        selectedCountryCodeError: false,
        country: "India",
        countryError: false,
        organisation: "",
        organisationError: false
    });
    const [pagination] = useState({
        pageIndex: 0,
        pageSize: 10, //customize the default page size
    });

    useEffect(() => {
        dispatch(fetchAllRoles());
        dispatch(fetchActiveUsers(pagination.pageIndex, pagination.pageSize));
    }, [dispatch, pagination.pageIndex, pagination.pageSize]);


    const handleDialogOpen = () => {
        setState({
            ...state, isDialogOpen: true, mode: "ADD", selectedCountryCode: "91",
            selectedRoleId: "", organisation: "",
            organisationError: false,   country: "India",
            countryError: false, selectedRoleIdError: false, firstNameError: false, lastNameError: false, cityError: false, addressLine1Error: false, addressLine2Error: false, userStateError: false, phoneNumber: "", phoneNumberError: false, selectedCountryCodeError: false, pincodeError: false, firstName: "", lastName: "", addressLine1: "", addressLine2: "", city: "", userState: "", pincode: "", email: "", emailError: false, status: "ACTIVE", dateOfBirth: null
        });
    };

    const handleCountryChange = (event, newValue) => {
        setState((prevState) => ({
            ...prevState,
            selectedCountryCode: newValue ? newValue.phone : "", selectedCountryCodeError: false,
            country: newValue ? newValue.label : "", countryError: false,
        }));
    };
    const handleEdit = useCallback((rowData) => {
        // let formattedDate = null
        // if (!!rowData.userProfile.date_of_birth) {
        //     let originalDate = rowData.userProfile.date_of_birth;
        //     let dateParts = originalDate.split("-");
        //     formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        // }
        setState({
            ...state,
            isDialogOpen: true,
            mode: "EDIT",
            id: rowData.id,
            selectedRoleId: rowData.userRoles[0].role_id,
            firstName: rowData.userProfile.first_name,
            lastName: rowData.userProfile.last_name,
            email: rowData.userProfile.email,
            phoneNumber: rowData.userProfile.phone_number,
            addressLine1: rowData.userProfile.address_line1,
            addressLine2: rowData.userProfile.address_line2 || "",
            city: rowData.userProfile.city,
            status: rowData.status,
            selectedCountryCode: rowData.userProfile.country_code,
            country: rowData.userProfile.country,
            organisation: rowData.userProfile.organization || "",
            userState: rowData.userProfile.state,
            pincode: rowData.userProfile.zip_code,
            firstNameError: false,
            selectedRoleIdError: false,
            lastNameError: false,
            addressLine1Error: false,
            addressLine2Error: false,
            selectedCountryCodeError: false,
            cityError: false,
            userStateError: false,
            pincodeError: false,
            countryError: false,
            emailError: false,
            phoneNumberError: false,
            organisationError: false
            // dateOfBirth: moment(formattedDate, "DD/MM/YYYY")
        })
    },[state, setState]);

    const handleDialogClose = () => {
        setState({ ...state, isDialogOpen: false })
    };
    const roleSelectAction = (e) => {
        setState({ ...state, selectedRoleId: e.target.value, selectedRoleIdError: false })
    };

    const handleSubmit = () => {
        let { id, firstName, selectedRoleId, lastName, organisation, addressLine1, selectedCountryCode, country, addressLine2, city, phoneNumber, pincode, email, status, mode, dateOfBirth } = state;
        let isError = false;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (selectedRoleId === '' || selectedRoleId === null || selectedRoleId === undefined) {
            setState((prevState) => ({ ...prevState, selectedRoleIdError: true }))
            isError = true;
        }
        if (firstName === '' || firstName === null || firstName === undefined) {
            setState((prevState) => ({ ...prevState, firstNameError: true }))
            isError = true;
        }

        if (lastName === '' || lastName === null || lastName === undefined) {
            setState((prevState) => ({ ...prevState, lastNameError: true }))
            isError = true;
        }
        if (addressLine1 === '' || addressLine1 === null || addressLine1 === undefined) {
            setState((prevState) => ({ ...prevState, addressLine1Error: true }))
            isError = true;
        }
        if (city === '' || city === null || city === undefined) {
            setState((prevState) => ({ ...prevState, cityError: true }))
            isError = true;
        }
        if (email === '' || email === null || email === undefined || !emailRegex.test(email)) {
            setState((prevState) => ({ ...prevState, emailError: true }))
            isError = true;
        }
        if (phoneNumber === '' || phoneNumber === null || phoneNumber === undefined || phoneNumber.length < 10) {
            setState((prevState) => ({ ...prevState, phoneNumberError: true }))
            isError = true;
        }
        if (selectedCountryCode === '' || selectedCountryCode === null || selectedCountryCode === undefined) {
            setState((prevState) => ({ ...prevState, selectedCountryCodeError: true }))
            isError = true;
        }
        if (country === '' || country === null || country === undefined) {
            setState((prevState) => ({ ...prevState, countryError: true }))
            isError = true;
        }
        if (organisation === '' || organisation === null || organisation === undefined) {
            setState((prevState) => ({ ...prevState, organisationError: true }))
            isError = true;
        }


        // if (userState === '' || userState === null || userState === undefined) {
        //     setState((prevState) => ({ ...prevState, userStateError: true }))
        //     isError = true;
        // }
        if (pincode === '' || pincode === null || pincode === undefined) {
            setState((prevState) => ({ ...prevState, pincodeError: true }))
            isError = true;
        }
        if (isError === false) {
            let obj = {
                role_id: selectedRoleId,
                first_name: firstName,
                last_name: lastName,
                address_line1: addressLine1,
                address_line2: addressLine2,
                email: email,
                phone_number: phoneNumber,
                city: city,
                date_of_birth: dateOfBirth,
                country_code: selectedCountryCode,
                country: country,
                organization: organisation,
                zip_code: pincode,
                status: status
            };
            if (mode === "ADD") {
                dispatch(addNewUser(obj, state, setState));
            }
            else {
                obj.id = id;
                dispatch(editUser(obj, state, setState));
            }
        };

    };

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.userProfile.first_name} ${row.userProfile.last_name}`,
                id: 'name',
                header: 'Name',
                size: 150,
                muiTableHeadCellProps: {
                    align: 'left',
                }
            },
            {
                accessor: 'role',
                header: 'Role',
                size: 120,
                Cell: ({ row }) => {
                    const roleName = !!row.original.userRoles && !!row.original.userRoles[0].role && row.original.userRoles[0].role.name
                    return (
                        <>
                            {roleName}
                        </>
                    )
                }
            },
            {
                accessorKey: 'userProfile.phone_number',
                header: 'Phone Number',
                size: 150,
            },
            {
                accessorKey: 'userProfile.email',
                header: 'Email',
                size: 150,
                muiTableHeadCellProps: {
                    align: 'center',
                },
            },
            // {
            //     accessorKey: 'status',
            //     header: 'Status',
            //     size: 150,
            // },
            {
                accessorKey: 'status',
                header: 'Status',
                grow: false,
                minSize: 50,
                Cell: ({ row }) => (
                    <>
                        {
                            !!row.original.status && row.original.status === "ACTIVE" ? <Chip size="small" label="Active" style={customStyles.activeChip} /> : <Chip color="primary" size="small" label="Inactive" style={customStyles.inActiveChip} />
                        }
                    </>
                )
            },
            {
                accessorKey: 'action',
                header: 'Action',
                size: 150,
                Cell: ({ row }) => {
                    let roleCode = row.original?.userRoles[0].role?.code;
                    return (
                        <>
                            <Grid container >
                                <Grid item xs={12} style={{ marginRight: 10 }}>
                                    <CustomPermission screenName={'Users'} feature={'edit'} roleCode={roleCode}
                                    //  enable={showEdit(roleCode, userData.role?.code)}
                                    >
                                        <IconButton>
                                            <Tooltip title={'Update User'}>
                                                <ModeEditIcon onClick={() => handleEdit(row.original)} />
                                            </Tooltip>
                                        </IconButton>
                                    </CustomPermission>
                                </Grid>
                            </Grid>
                        </>
                    )
                }
            }
        ],
        [handleEdit],
    );

    // const showEdit = (roleCode, logedInRoleCode) => {
    //     if (logedInRoleCode === 'DVP') {
    //         return true;
    //     } else if (logedInRoleCode !== 'DVP' && roleCode === 'DVP') {
    //         return false;
    //     } else {
    //         return permission?.edit
    //     }
    // }

    const tableView = useMaterialReactTable({
        columns,
        data: users,
        enableDensityToggle: false,
        initialState: { pagination: { pageSize: 10, pageIndex: 0 }, density: "compact" },
        enableFullScreenToggle: false,
        enableRowNumbers: true,
        rowNumberDisplayMode: "static",
        muiTableBodyCellProps: {
            style: styles.tableBodyCell
        },
        muiTableHeadCellProps: {
            style: styles.tableHeadCell
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box>
                <CustomPermission screenName={'Users'} feature={'add'}>
                    <Button size={"small"} sx={customStyles.buttonText} variant="contained" color="primary" startIcon={<PersonAddIcon />} onClick={() => handleDialogOpen()} >
                        ADD USER
                    </Button>
                </CustomPermission>
            </Box>
        )
    });

    return (
        <Grid container justifyContent={'center'} spacing={2}>
            <Grid item xs={12}>
                <MaterialReactTable table={tableView} />
            </Grid>
            <Dialog open={state.isDialogOpen} maxWidth={"sm"}>
                <DialogTitle>
                    {state.mode === "ADD" ? <Typography style={customStyles.boldText}>Add User</Typography> : <Typography style={customStyles.boldText}>Update User</Typography>}
                </DialogTitle>
                <IconButton
                    onClick={() => handleDialogClose()}
                    sx={styles.cancelButton}
                >
                    <DisabledByDefaultRoundedIcon sx={{ width: 30, height: 30 }} />
                </IconButton>
                <DialogContent dividers >
                    <Grid container spacing={3} justifyContent={'flex-start'} alignItems={'center'}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl fullWidth error={state.selectedRoleIdError}>
                                <InputLabel>Role</InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    size='small'
                                    displayEmpty
                                    label="role"
                                    value={state.selectedRoleId}
                                    sx={{ marginTop: '10px' }}
                                    onChange={roleSelectAction}
                                >
                                    {!!roles && roles.slice().sort((a, b) => a.name.localeCompare(b.name))
                                        .map((item) =>
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        )}
                                </Select>
                                {state.selectedRoleIdError &&
                                    <FormHelperText>Please select the Role</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.firstName} fullWidth placeholder={"First Name"} onChange={(e) => setState({ ...state, firstName: e.target.value.trimStart(), firstNameError: false })} size='small' error={state.firstNameError} />
                            {state.firstNameError &&
                                <FormHelperText style={styles.formTextStyle}>Please enter the First name</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.lastName} placeholder={"Last Name"} fullWidth onChange={(e) => setState({ ...state, lastName: e.target.value.trimStart(), lastNameError: false })} size='small' error={state.lastNameError} />
                            {state.lastNameError &&
                                <FormHelperText style={styles.formTextStyle}>Please enter the last name</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.email} placeholder={"Email"} fullWidth onChange={(e) => setState({ ...state, email: e.target.value.trimStart(), emailError: false })} size='small' error={state.emailError}
                                helperText={state.emailError && 'Please enter a valid email'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Autocomplete
                                                sx={{ width: 95, marginLeft: -2, border: 0 }}
                                                options={countries}
                                                size="small"
                                                // clearText={false}
                                                disableClearable
                                                value={
                                                    countries.find(
                                                        (country) => country.phone === state.selectedCountryCode
                                                    ) || null
                                                }
                                                onChange={handleCountryChange}
                                                getOptionLabel={(option) => option.phone}
                                                autoHighlight
                                                renderOption={(props, option) => {
                                                    const { key, ...optionProps } = props;
                                                    return (
                                                        <Box
                                                            style={{ width: 105 }}
                                                            key={option.code}
                                                            component="li"
                                                            sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
                                                            {...optionProps}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                alt=""
                                                            />
                                                            {option.phone}
                                                        </Box>
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            width: 100,
                                                            border: 0,
                                                            "& .MuiOutlinedInput-root": {
                                                                "& fieldset": {
                                                                    border: "0px solid transparent",
                                                                    borderRight: "1px solid gray",
                                                                    //border: "none"
                                                                }
                                                            }
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps
                                                        }}
                                                    />
                                                )}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                value={state.phoneNumber} fullWidth placeholder={"Phone Number"} inputProps={{ maxLength: 10 }} onChange={(e) => setState({ ...state, phoneNumber: e.target.value.replace(/\D/g, ""), phoneNumberError: false })} size='small' error={state.phoneNumberError} />
                            {state.phoneNumberError &&
                                <FormHelperText style={styles.formTextStyle}>Please enter the phonenumber</FormHelperText>
                            }

                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.organisation} placeholder={"Organization"} fullWidth onChange={(e) => setState({ ...state, organisation: e.target.value.trimStart(), organisationError: false })} size='small' error={state.organisationError} />
                            {state.organisationError &&
                                <FormHelperText style={styles.formTextStyle}>Please enter the organization</FormHelperText>
                            }
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Date of Birth"
                                        value={state.dateOfBirth}
                                        disableFuture
                                        format="DD/MM/YYYY"
                                        onChange={(date) =>
                                            setState({
                                                ...state,
                                                dateOfBirth: date,
                                            })
                                        }
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true,
                                                variant: 'outlined',
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                                inputProps: {
                                                    placeholder: 'DD/MM/YYYY',
                                                },
                                                sx: styles.dobTextField,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid> */}
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.addressLine1} fullWidth placeholder={"Address 1"} size='small' error={state.addressLine1Error} onChange={(e) => setState({ ...state, addressLine1: e.target.value.trimStart(), addressLine1Error: false })} />
                            {state.addressLine1Error &&
                                <FormHelperText style={styles.formTextStyle}>Please enter the addressLine1</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.addressLine2} fullWidth placeholder={"Address 2"} size='small' onChange={(e) => setState({ ...state, addressLine2: e.target.value.trimStart(), addressLine2Error: false })} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.city} fullWidth placeholder={"City"} size='small' error={state.cityError} onChange={(e) => setState({ ...state, city: e.target.value.trimStart(), cityError: false })} />
                            {state.cityError &&
                                <FormHelperText style={styles.formTextStyle}>Please enter the city</FormHelperText>
                            }
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField fullWidth value={state.userState} placeholder={"State"} size='small' error={state.userStateError} onChange={(e) => setState({ ...state, userState: e.target.value.trimStart(), userStateError: false })} />
                                {state.userStateError &&
                                    <FormHelperText style={styles.formTextStyle}>Please enter the state</FormHelperText>
                                }
                            </Grid> */}
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.pincode} fullWidth placeholder={"Pincode"} size='small' error={state.pincodeError} inputProps={{ maxLength: 6 }} onChange={(e) => setState({ ...state, pincode: e.target.value.replace(/\D/g, ""), pincodeError: false })} />
                            {state.pincodeError &&
                                <FormHelperText style={styles.formTextStyle}>Please enter the pincode</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.country} fullWidth placeholder={"Country"} disabled size='small' inputProps={{ maxLength: 6 }} onChange={(e) => setState({ ...state, country: e.target.value, countryError: false })} />
                            {state.countryError &&
                                <FormHelperText style={styles.formTextStyle}>Please enter the country</FormHelperText>
                            }
                        </Grid>
                        {state.mode === "EDIT" && <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                            <FormControl sx={styles.statusField}>
                                {/* <FormLabel sx={{ color: 'common.black', '&.Mui-focused': { color: 'common.black' } }}>Status</FormLabel> */}
                                <Typography style={customStyles.regularText}>Status</Typography>
                                <RadioGroup
                                    row
                                    value={state.status}
                                    onChange={(e) => setState({ ...state, status: e.target.value })}
                                >
                                    <FormControlLabel value="ACTIVE" control={<Radio color="primary" />} label="Active" />
                                    <FormControlLabel value="INACTIVE" control={<Radio color="primary" />} label="Inactive" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant='contained' color='error' style={customStyles.cancelButtonText} onClick={() => handleDialogClose()} sx={customStyles.cancelButtonText}>
                        Cancel
                    </Button>
                    <Button autoFocus variant="contained" color="primary" onClick={() => handleSubmit()}
                    // sx={{ '&.MuiButton-root:hover': { bgcolor: "#2F7FFF" } }}
                    >
                        {state.mode === 'ADD' ? <Typography style={customStyles.cancelButtonText}>Submit</Typography> : <Typography style={customStyles.cancelButtonText}>Update</Typography>}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>)
}
export default ActiveUser;  