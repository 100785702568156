import { createSlice } from '@reduxjs/toolkit';

// Initial state for the test drive slice
const initialState = {
    testDrive: []
}

export const testDriveReducer = createSlice({
    name: 'testDriveReducer',
    initialState,
    reducers: {
        /**
         * Fetch test drive data and update the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Array} action.payload - The list of test drives
         */
        fetchTestDrive: (state, action) => {
            state.testDrive = action.payload;
        }
    }
});

// Export actions to be used in components
export const { fetchTestDrive } = testDriveReducer.actions;
// Export reducer to be used in the store
export default testDriveReducer.reducer
