import { createSlice } from "@reduxjs/toolkit";

// Initial state for the role slice
const initialState = {
    roles: []
}

export const roleReducer = createSlice({
    name: 'role',
    initialState,
    reducers: {
        /**
         * Fetch roles and update the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Array} action.payload - The list of roles
         */
        fetchRoles: (state, action) => {
            state.roles = action.payload;
        },
        /**
         * Create a new role and update the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Object} action.payload - The new role to be added
         */
        createRoles: (state, action) => {
            state.roles = [action.payload, ...state.roles]
        },
        /**
         * Edit an existing role and update the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Object} action.payload - The edited role
         */
        editRole: (state, action) => {
            state.roles = state.roles.map((item) => item.id === action.payload.id ? action.payload : item)
        }
    }
});

// Export actions to be used in components
export const { fetchRoles, createRoles, editRole } = roleReducer.actions

// Export reducer to be used in the store
export default roleReducer.reducer;