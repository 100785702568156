import { createSlice } from "@reduxjs/toolkit";

// Initial state for the screen slice
const initialState = {
    screens: []
}

export const screenReducer = createSlice({
    name: 'screenReducer',
    initialState,
    reducers: {
         /**
         * Create a new screen and add it to the state
         * @param {Object} state - The current state
         * @param {Object} payload - The new screen to be added
         */
        createScreen: (state, { payload }) => {
            state.screens.push(payload);
        },
        /**
         * Fetch screens and update the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Array} action.payload - The list of screens
         */
        fetchScreen: (state, action) => {
            state.screens = action.payload;
        },
        /**
         * Update an existing screen and modify the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Object} action.payload - The updated screen
         */
        updateScreen: (state, action) => {
            state.screens = state.screens.map((item) => item.id === action.payload.id ? action.payload : item)
        }
    }
});

// Export actions to be used in components
export const { createScreen, fetchScreen, updateScreen } = screenReducer.actions;
// Export reducer to be used in the store
export default screenReducer.reducer