import { useState } from 'react';
import { TextField, Grid, Button, Typography, FormHelperText } from '@mui/material';
import { useDispatch } from 'react-redux';
import { userLogin } from '../../store/actions/sessionAction';
import { useNavigate } from "react-router-dom";
import backImage from '../../assets/backImageLogin.jpg'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';

export default function Login() {

    const [state, setState] = useState({
        userName: "",
        userNameError: false,
        password: "",
        passwordError: "",
        validationError: false,
        errorMessage: 'Invalid username or password'
    });
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show)
    };

    const handleLogin = () => {
        let { userName, password } = state;

        let isError = false;
        if (userName === '' || userName === null || userName === undefined) {
            setState((prevState) => ({ ...prevState, userNameError: true }));
            isError = true;
        }

        if (password === '' || password === null || password === undefined) {
            setState((prevState) => ({ ...prevState, passwordError: true }));
            isError = true;
        }

        if (isError === false) {
            let data = { user_name: userName, password: password };
            dispatch(userLogin(data, navigate, state, setState));
        }
    };

    const handleForgotPassword = () => {
        navigate("/forgot-password");
    };

    const onEnterAction = (event) => {
        if (event.key === 'Enter') {
            handleLogin()
        }
    }

    const styles = {
        containerBackground: {
            backgroundImage: `url(${backImage})`,
            height: '100vh',
            backgroundSize: 'cover',
            filter: 'blur(0px)',
        },
        backgroundContainer: {
            borderRadius: '4%',
            background: 'rgba( 255, 255, 255, 0.25 )',
            boxshadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
            backdropFilter: 'blur( 4px )',
            border: '1px solid rgba( 255, 255, 255, 0.18 )',
        },
        loginHeader: {
            color: 'white',
            textAlign: 'center',
            marginTop: '5%',
            fontWeight: 'bold',
            fontSize: '18px'
        },
        loginButton: {
            padding: "8px 70px",
            borderRadius: '16px',
            fontWeight: 'bold',
            background: 'linear-gradient(to right, #183883, #E62D38)'
        },
        loginButtonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 20
        },
        passwordErrorMessage: {
            color: '#fff',
            backgroundColor: 'red',
            padding: '0px 10px 0px 10px'
        },
        forgotPasswordErrorText: {
            color: 'white',
            textTransform: 'capitalize',
            fontWeight: 'bold'
        },
    }

    return (
        <>
            <Grid onKeyDown={(e) => onEnterAction(e)} container justifyContent='center' alignItems='center' style={styles.containerBackground}>
                <Grid item xs={10} sm={5} md={4} lg={3} xl={3}>
                    <Grid container>
                        <Grid item xs={12} style={styles.backgroundContainer}>
                            <Typography style={styles.loginHeader}>LOGIN</Typography>
                            <Grid container rowGap={3} justifyContent={'center'} style={{ marginTop: '10%', }}>
                                <Grid item xs={8} style={{ marginBottom: '5%' }}>
                                    <TextField
                                        inputProps={{
                                            style: {
                                                color: '#fff',
                                            }
                                        }} placeholder="Username" color='secondary' autoComplete='off' variant='outlined' fullWidth focused value={state.userName} label="Username" size={'medium'} onChange={(e) => setState({ ...state, userName: e.target.value.trim(), userNameError: false, validationError: false })} />
                                    {state.userNameError &&
                                        <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Please enter the username</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={8}>
                                    <InputLabel style={{ color: '#fff' }}>Password</InputLabel>
                                    <OutlinedInput
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword()}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        placeholder=' Password'
                                        sx={{ color: '#fff' }}
                                        fullWidth
                                        color='secondary' variant='outlined' value={state.password} size={'medium'} onChange={(e) => setState({ ...state, password: e.target.value.trim(), passwordError: false, validationError: false })}
                                    />
                                    {/* </FormControl> */}
                                    {state.passwordError === true &&
                                        <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Please enter the password</FormHelperText>
                                    }
                                </Grid>
                                {state.validationError === true &&
                                    <Grid container >
                                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography style={styles.passwordErrorMessage}>{state.errorMessage}</Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                    <Button style={styles.forgotPasswordErrorText} onClick={() => handleForgotPassword()}> Forgot Password ?</Button>
                                </Grid>
                                <Grid item xs={8} style={styles.loginButtonContainer}>
                                    <Button style={styles.loginButton} type="submit" variant='contained' onClick={() => handleLogin()}>Login</Button>
                                </Grid>
                                {/* <Typography style={{ color: '#fff', fontSize: '14px', margin:10 }}><a target='_blank' href='https://www.tvsbrakelinings.com/index.php' style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff'}}>Privacy policy</a> and <a target='_blank' href='https://www.tvsbrakelinings.com/index.php' style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff'}}> Terms of Service</a></Typography> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ position: 'absolute', bottom: 0, justifyContent: 'flex-end', backgroundColor: '#0A3058' }}>
                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                    <Grid container justifyContent={'space-around'}>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/privacy-policy`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Privacy policy</a></Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/terms-and-conditions`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Terms of Service</a></Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/about`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>About</a></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
