import screenReducer from './screenReducer';
import roleReducer from './roleReducer';
import testDriveReducer from './testDriveReducer';
import userReducer from './userReducer';
import alertReducer from './alertReducer';
import sessionReducer from './sessionReducer';
import dashboardReducer from './dashboardReducer';
import clientReducer from './clientReducer';
import generateCodeReducer from './generateCodeReducer';

// Combine individual reducers into a root reducer
export const rootReducer = {
    screen: screenReducer,
    role: roleReducer,
    testDrive: testDriveReducer,
    user: userReducer,
    alert: alertReducer,
    session: sessionReducer,
    dashboard: dashboardReducer,
    client: clientReducer,
    generateCode:generateCodeReducer
}

export default rootReducer;