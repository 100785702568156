import accountApi from "../../api/accountApi";
import { fetchGenerateCode, createGenerateCode, editGenerateCode } from "../reducer/generateCodeReducer";
import { displayAlert } from "../reducer/alertReducer";

/**
 * Fetch all generated referral codes 
 * @function
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchAllGeneratedCode() {
    return async (dispatch) => {
        accountApi.get(`referral-code`).then(async (response) => {
            if (response.status === 'success') {
                dispatch(fetchGenerateCode(response.payload))
            }
        });
    }
};

/**
 * Create a new referral code
 * @function
 * @param {Object} data - The data for creating a new referral code
 * @param {Object} state - The current state of the component
 * @param {Function} setState - The function to update the state of the component
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function createByGeneratedCode(data, state, setState) {
    return async (dispatch) => {
        accountApi.post(`referral-code`, data).then(async (response) => {
            if (response.status === 'success') {
                dispatch(createGenerateCode(response.payload));
                setState({ ...state, isDialogOpen: false });
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "success", alertMessage: "Code generated successfully" }));
            }
            else {
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "error", alertMessage: "Code already Exists" }))
            }
        });
    }
};

/**
 * Update an existing referral code 
 * @function
 * @param {Object} data - The data for updating the referral code, including the ID and new values
 * @param {Object} state - The current state of the component
 * @param {Function} setState - The function to update the state of the component
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function updateGeneratedCode(data, state, setState) {
    return async (dispatch) => {
        accountApi.put(`referral-code/${data.id}`, data).then(async (response) => {
            if (response.status === 'success') {
                dispatch(editGenerateCode(response.payload))
                setState({ ...state, isDialogOpen: false })
            }
        });
    }
}