import { createSlice } from "@reduxjs/toolkit";

// Initial state for the client slice
const initialState = {
    clients: []
}

export const clientReducer = createSlice({
    name: 'clientReducer',
    initialState,
    reducers: {
        /**
        * Fetch clients and update the state
        * @param {Object} state - The current state
        * @param {Object} action - The action object containing payload
        * @param {Array} action.payload - The list of clients
        */
        fetchClients: (state, action) => {
            state.clients = action.payload;
        },
    }
});

// Export actions to be used in components
export const { fetchClients } = clientReducer.actions;
// Export reducer to be used in the store
export default clientReducer.reducer