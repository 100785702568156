import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab, Box, Badge } from "@mui/material";
import ActiveUsers from "./ActiveUsers";
import RequestedUsers from "./RequestedUsers";
import RejectedUsers from "./RejectedUsers";
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BlockIcon from '@mui/icons-material/Block';
import { customStyles } from "../../helpers/customStyles";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUserMetrics } from '../../store/actions/userAction';

function Users(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const activeUserCount = useSelector((state) => state.user.userCount.activeUsers);
  const requestedUserCount = useSelector((state) => state.user.userCount.requestedUsers);
  const rejectedUserCount = useSelector((state) => state.user.userCount.rejectedUsers);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchAllUserMetrics());
  }, [dispatch]);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="user tabs"
            variant="fullWidth"
            textColor="inherit"
          >
            <Tab
              icon={
                <Badge badgeContent={activeUserCount} color="error">
                  <PeopleIcon sx={{ color: '#000' }} />
                </Badge>
              }
              iconPosition="start"
              label="Users"
              sx={value === 0 ? customStyles.activeText : customStyles.regularText}
            />
            <Tab
              label="Requests"
              iconPosition="start"
              sx={value === 1 ? customStyles.activeText : customStyles.regularText}
              icon={
                <Badge badgeContent={requestedUserCount} color="error" showZero>
                  <AssignmentIcon sx={{ color: '#000' }} />
                </Badge>
              }
            />
            <Tab icon={
              <Badge badgeContent={rejectedUserCount} color="error" showZero>
                <BlockIcon sx={{ color: '#000' }} />
              </Badge>
            } label="Rejected" iconPosition="start" sx={value === 2 ? customStyles.activeText : customStyles.regularText} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ p: 2 }}>
            {value === 0 && (
              <ActiveUsers />
            )}
            {value === 1 && (
              <RequestedUsers />
            )}
            {value === 2 && (
              <RejectedUsers />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Users;
