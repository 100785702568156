import API from '../../../src/api/accountApi';
import { createScreen, fetchScreen, updateScreen } from '../reducer/screenReducer';
import { displayAlert } from "../reducer/alertReducer";

/**
 * Add a new screen 
 * @function
 * @param {Object} data - The data for creating a new screen
 * @param {Function} setIsOpen - The function to close the dialog or form
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function addScreen(data, setIsOpen) {
    return async (dispatch) => {
        API.post('screen', data).then((response) => {
            if (response.status === 'success') {
                dispatch(createScreen(response.payload));
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "success", alertMessage: "Screen added successfully" }));
                setIsOpen(false);
            }
        });
    }
}

/**
 * Fetch all screens 
 * @function
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchAllScreen() {
    return async (dispatch) => {
        API.get('screen').then((response) => {
            if (response.status === 'success') {
                dispatch(fetchScreen(response.payload));
            }
        });
    }
}

/**
 * Fetch all screens for role 
 * @function
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchAllScreenForRole() {
    return async (dispatch) => {
        API.get('screen').then((response) => {
            if (response.status === 'success') {
                dispatch(fetchScreen(response.payload));
                // setState({...state, screens: response.payload});
            }
        });
    }
}

/**
 * Edit an existing screen 
 * @function
 * @param {Object} data - The data for updating the screen
 * @param {string} id - The ID of the screen to be updated
 * @param {Function} setIsOpen - The function to close the dialog 
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function editScreen(data, id, setIsOpen) {
    return async (dispatch) => {
        API.put(`screen/${id}`, data).then((response) => {
            if (response.status === 'success') {
                dispatch(updateScreen(response.payload));
                setIsOpen(false);
            }
        });
    }
}