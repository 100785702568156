import { Grid, Typography } from '@mui/material';
import LogoTvs from '../../assets/logo_tvs.webp';
import { customStyles, fontFamily } from '../../helpers/customStyles';

export default function PrivacyPolicy() {

    const styles = {
        headerText: {
            fontFamily: "Arial, Helvetica, sans-serif",
            fontSize: "28px",
            fontWeight: "600",
            color: '#0D4576',
        },
        mainHeaderText: {
            fontSize: '24px',
            color: '#519BCB',
            textAlign: 'center',
            fontFamily: fontFamily.boldFont
        },
        subHeaderTextBlack: {
            fontSize: '18px',
            fontFamily: fontFamily.boldFont
        },
        subHeaderTextBlue: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#519BCB',
            fontFamily: fontFamily.regularFont
        },
        infoTextStyleRed: {
            fontFamily: fontFamily.regularFont,
            fontSize: "16px",
            fontWeight: 'normal',
            color: 'red'
        }
    }

    return (
        <Grid container >
            <Grid item xs={12} style={{ position: 'fixed', top: 0, backgroundColor: '#ffff', width: '100%', zIndex: 10000000 }}>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}><img alt='logo' src={LogoTvs} style={{}} height={130} width={160} /></Grid>
                    <Grid item xs={8} ><Typography style={styles.headerText} >SUNDARAM BRAKE LININGS LIMITED</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', rowGap: 20, marginRight: '10%', marginLeft: '10%', position: 'relative', top: 150, paddingBottom: '5%' }}>
                <Typography style={styles.mainHeaderText}>Privacy Policy</Typography>
                <Typography style={styles.subHeaderTextBlack}>Sundaram Brake Linings Limited</Typography>
                <Typography style={customStyles.infoTextStyle}>Sundaram Brake Linings Limited (SBL), a company incorporated under the Indian
                    Companies Act, 1956 and having its Registered Office at Padi, Chennai 600 050, India
                    built the Brake Trail App as an R&amp;D data acquisition assistance tool. This SERVICE is
                    provided by SBL at no cost and is intended for use as is.</Typography>
                <Typography style={customStyles.infoTextStyle}>This page is used to inform visitors regarding our policies with the collection, use, and
                    disclosure of Personal Information if anyone decided to use our Service.</Typography>
                <Typography style={customStyles.infoTextStyle}>If you choose to use our Service, then you agree to the collection and use of information in
                    relation to this policy. The Personal Information that we collect is used for verifying the
                    identity of the user and for providing our service. We will not use or share your information
                    with anyone except as described in this Privacy Policy.</Typography>
                <Typography style={customStyles.infoTextStyle}>The terms used in this Privacy Policy have the same meanings as in our Terms and
                    Conditions, which is accessible at Brake Trail unless otherwise defined in this Privacy
                    Policy.</Typography>
                <Typography style={styles.subHeaderTextBlue}>Information Collection and Use:</Typography>
                <Typography style={customStyles.infoTextStyle}>For a better experience, while using our Service, we may require you to provide us with
                    certain personally identifiable information, including but not limited to Name, Address,
                    phone number, email-id &amp; precise location.</Typography>
                <Typography style={customStyles.infoTextStyle}>The app does use third party services that may collect information used to identify you.</Typography>
                <Typography style={customStyles.infoTextStyle}>Reference to third party service providers used by the app are below:</Typography>
                <Typography style={customStyles.infoTextStyle}>1. Google Play Services</Typography>
                <Typography style={customStyles.infoTextStyle}>2. Amazon Web Services</Typography>
                <Typography style={styles.subHeaderTextBlue}>Log Data:</Typography>
                <Typography style={customStyles.infoTextStyle}>We want to inform you that whenever you use our Service, in a case of an error in the app,
                    we collect data and information (through third party products) on your phone called Log
                    Data. This Log Data may include information such as your device Internet Protocol (“IP”)
                    address, device name, operating system version, the configuration of the app when
                    utilizing our Service, the time and date of your use of the Service, and other statistics.</Typography>
                <Typography style={styles.subHeaderTextBlue}>Cookies:</Typography>
                <Typography style={customStyles.infoTextStyle}>Cookies are files with a small amount of data that are commonly used as anonymous
                    unique identifiers. These are sent to your browser from the websites that you visit and are
                    stored on your device&#39;s internal memory. This Service does not use these “cookies” explicitly. However, the app may use third party
                    code and libraries that use “cookies” to collect information and improve their services. You
                    have the option to either accept or refuse these cookies and know when a cookie is being
                    sent to your device. If you choose to refuse our cookies, you may not be able to use some
                    portions of this Service.</Typography>
                <Typography style={styles.subHeaderTextBlue}>Security:</Typography>
                <Typography style={customStyles.infoTextStyle}>We value your trust in providing us your Personal Information, thus we are striving to use
                    commercially acceptable means of protecting it. But remember that no method of
                    transmission over the internet, or method of electronic storage is 100% secure and
                    reliable, and we cannot guarantee its absolute security.</Typography>
                <Typography style={styles.subHeaderTextBlue}> Children&#39;s Privacy:</Typography>
                <Typography style={customStyles.infoTextStyle}> These Services do not address anyone under the age of 13. We do not knowingly collect
                    personally identifiable information from children under 13. In a case We discover that a
                    child under 13 has provided us with personal information, we immediately delete this from
                    our servers. If you are a parent or guardian and you are aware that your child has provided
                    us with personal information, please contact us so that we will be able to take necessary
                    actions.</Typography>
                <Typography style={styles.subHeaderTextBlue}> Changes to This Privacy Policy:</Typography>
                <Typography style={customStyles.infoTextStyle}> We may update our Privacy Policy from time to time. Thus, you are advised to review this
                    page periodically for any changes. We will notify you of any changes by posting the new
                    Privacy Policy on this page.</Typography>
                <Typography style={customStyles.infoTextStyle}>This Privacy Policy was last updated Sep.1, 2024.</Typography>
                <Typography style={styles.subHeaderTextBlue}>Contact Us:</Typography>
                <Typography style={customStyles.infoTextStyle}>If you have any questions or suggestions about our Privacy Policy, do not hesitate to
                    contact us at privacy@tvssbl.com</Typography>
            </Grid>
            {/* <Grid container style={{ position: 'fixed', bottom: 0, display: 'flex', justifyContent: 'flex-end', backgroundColor: '#0A3058', padding: 5 }}>
                <Grid item xs={5} >
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={3}>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/terms-and-conditions`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}> Terms of Service</a></Typography>
                        </Grid>
                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/about`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}> About</a></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid container style={{ position: 'fixed', bottom: 0, justifyContent: 'flex-end', backgroundColor: '#0A3058' }}>
                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                    <Grid container justifyContent={'space-around'}>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/terms-and-conditions`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Terms of Service</a></Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/about`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>About</a></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
