import { createSlice } from '@reduxjs/toolkit';

// Initial state for the session slice
const initialState = {
    user: {},
    accessToken: ""
};

export const sessionReducer = createSlice({
    name: 'session',
    initialState,
    reducers: {
        /**
         * Log in a user and update the state
         * @param {Object} state - The current state
         * @param {Object} action - The action object containing payload
         * @param {Object} action.payload.payload - The user information
         * @param {string} action.payload.token.accessToken - The access token
         */
        login: (state, action) => {
            state.user = action.payload.payload;
            state.accessToken = action.payload.token.accessToken;
        }

    }
});

// Export actions to be used in components
export const { login } = sessionReducer.actions;
// Export reducer to be used in the store
export default sessionReducer.reducer;