import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { TextField, Grid, Button, Typography, FormHelperText, OutlinedInput, InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../store/actions/userAction';
import { useNavigate, useLocation } from "react-router-dom";
import backImage from '../../assets/backImageLogin.jpg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

export default function ConfirmPassword() {
    const [state, setState] = useState({
        userName: "",
        userNameError: false,
        password: "",
        passwordError: false,
        confirmPassword: "",
        confirmPasswordError: false,
        verificationCode: "",
        verificationCodeError: false,
        passwordMismatchError: false,
        showPassword: false,
        openPassword: false,
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userName = location.state;

    useEffect(() => {
        if (userName === '' || userName === null) {
            navigate('/forgot-password')
        }
    }, [ navigate, userName]);

    const handleShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    const handleClickShowPassword = () => {
        setState({ ...state, openPassword: !state.openPassword })
    };

    const handleLogin = () => {
        let { verificationCode, password, confirmPassword } = state;

        let isError = false;
        if (verificationCode === '' || verificationCode === null || verificationCode === undefined || verificationCode.length < 6) {
            setState((prevState) => ({ ...prevState, verificationCodeError: true }));
            isError = true;
        }
        if (password === '' || password === null || password === undefined) {
            setState((prevState) => ({ ...prevState, passwordError: true }));
            isError = true;
        }
        if (confirmPassword === '' || confirmPassword === null || confirmPassword === undefined) {
            setState((prevState) => ({ ...prevState, confirmPasswordError: true }));
            isError = true;
        }
        if (password !== confirmPassword) {
            setState((prevState) => ({ ...prevState, passwordMismatchError: true }));
            isError = true;
        }

        if (isError === false) {
            let data = {}
            data.user_name = userName.user_name;
            data.password = password;
            data.verification_code = verificationCode;
            dispatch(resetPassword(data, navigate, state, setState));
        }
    };

    return (
        <>
            <div style={{
                // backgroundColor: '#FF3CAC',
                backgroundImage: `url(${backImage})`,
                height: '100vh', backgroundSize: 'cover', filter: 'blur(0px)'
            }}>

            </div>
            <Grid container style={{ justifyContent: 'center', alignItems: 'center', height: '100%', position: 'absolute', top: 0 }}>
                <Grid item xs={10} sm={6} md={4} lg={3} style={{
                    borderRadius: '4%',
                    background: 'rgba( 255, 255, 255, 0.25 )',
                    boxshadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
                    backdropFilter: 'blur( 4px )',
                    border: '1px solid rgba( 255, 255, 255, 0.18 )',
                }}>
                    <Typography style={{ color: 'white', textAlign: 'center', marginTop: '5%', fontWeight: 'bold', fontSize: '18px' }}>ConfirmPassword</Typography>
                    <Grid container rowGap={1.5} justifyContent={'center'} style={{ marginTop: '10%', }}>
                        <Grid item xs={8}>
                            <TextField
                                inputProps={{
                                    maxLength: 6,
                                    sx: {color: '#fff'}
                                }} placeholder="OTP" color='secondary' autoComplete='off' variant='outlined' fullWidth autoFocus={true} value={state.verificationCode} label="OTP" size={'medium'} onChange={(e) => setState({ ...state, verificationCode: e.target.value.replace(/\D/g, "").trim(), verificationCodeError: false })} />
                            {state.verificationCodeError &&
                                <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Please enter a valid verificationCode</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={8}>
                            <InputLabel style={{ color: '#fff' }}>Password</InputLabel>
                            <OutlinedInput
                                type={state.showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position = "end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleShowPassword()}
                                            edge='end'
                                        >
                                            {state.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                placeholder=' Password'
                                sx={{ color: '#fff' }}
                                fullWidth
                                color='secondary' variant='outlined' autoComplete='off'autoFocus={true} value={state.password} size={'medium'} onChange={(e) => setState({ ...state, password: e.target.value.trim(), passwordError: false, passwordMismatchError: false })} />
                            {state.passwordError &&
                                <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Please enter the password</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={8}>
                            <InputLabel style={{ color: '#fff' }}>ConfirmPassword</InputLabel>
                            <OutlinedInput
                                type={state.openPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position = "end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword()}
                                            edge='end'
                                        >
                                            {state.openPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="ConfirmPassword"
                                sx={{ color: '#fff' }}
                                color='secondary'
                                autoComplete='off'
                                variant='outlined'
                                fullWidth
                                autoFocus={true}
                                value={state.confirmPassword}
                                label="ConfirmPassword"
                                size={'medium'}
                                onChange={(e) => setState({ ...state, confirmPassword: e.target.value.trim(), confirmPasswordError: false, passwordMismatchError: false })} />
                            {state.confirmPasswordError &&
                                <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Please enter the confirmPassword</FormHelperText>
                            }
                            {state.passwordMismatchError &&
                                <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Passwords do not match</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
                            <Button style={{ padding: "8px 70px", borderRadius: '16px', fontWeight: 'bold', background: 'linear-gradient(to right, #183883, #E62D38)' }} type="submit" variant='contained' onClick={() => handleLogin()}>Confirm    </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
