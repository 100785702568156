import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { fetchAllClients } from '../../store/actions/clientAction';
import { useSelector, useDispatch } from 'react-redux';
import { customStyles } from '../../helpers/customStyles';

export default function Clients(props) {
    const dispatch = useDispatch();
    const clients = useSelector((state) => state.client.clients);

    React.useEffect(() => {
        dispatch(fetchAllClients('CLT'));
    }, [dispatch]);

    const columns = React.useMemo(() => [
        {
            accessorKey: 'first_name',
            header: 'First name',
            size: 100,
            Cell: ({ row }) => {
                return (
                    <>
                        {row?.original?.user?.userProfile?.first_name}

                    </>
                )
            }
        },
        {
            accessorKey: 'last_name',
            header: 'Last name',
            size: 100,
            Cell: ({ row }) => {
                return (
                    <>
                        {row?.original?.user?.userProfile?.last_name}
                    </>
                )
            }
        },
        {
            accessorKey: 'phone_number',
            header: 'Phone Number',
            size: 100,
            Cell: ({ row }) => {
                return (
                    <>
                        {row?.original?.user?.userProfile?.phone_number}
                    </>
                )
            }
        },
        {
            accessorKey: 'email', //normal accessorKey
            header: 'email',
            size: 100,
            Cell: ({ row }) => {
                return (
                    <>
                        {row?.original?.user?.userProfile?.email}
                    </>
                )
            }
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            Cell: ({ row }) => {
                return (
                    <>
                        {row?.original?.user?.status}
                    </>
                )
            }
        },
    ], [],
    );

    const tableView = useMaterialReactTable({
        columns,
        data: clients,
        enableDensityToggle: false,
        enableColumnActions: false,
        enableRowNumbers: true,
        enableFullScreenToggle: false,
        rowNumberDisplayMode: "static",
        muiTableBodyCellProps: {
            style: {
                fontFamily: 'Open-Sans-Regular', fontSize: '14px', color: '#000', height: 60
            }
        },
        muiTableHeadCellProps: {
            style: { textAlign: "center", backgroundColor: "#1976D2", color: '#ffff', fontFamily: 'Open-Sans-Bold' }
        },
        initialState: { pagination: { pageSize: 10, pageIndex: 0 }, density: "compact" },
    });

    return (
        <div >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography style={customStyles.boldText}>Clients</Typography>
                </Grid>
                <Grid item xs={12}>
                    <MaterialReactTable table={tableView} />
                </Grid>
            </Grid>
        </div>
    )
}