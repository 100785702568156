import { useState, useMemo, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, FormHelperText, Grid, Button, Typography, Box, Chip, } from '@mui/material';
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import { customStyles, fontFamily } from '../../helpers/customStyles';
import palette from '../../theme/palette';
import { fetchAllGeneratedCode, createByGeneratedCode, updateGeneratedCode } from '../../store/actions/generateCodeAction';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import moment from "moment";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import ModeEditIcon from '@mui/icons-material/ModeEdit';

const styles = {
    errorWarning: {
        color: palette.error.main,
        marginLeft: "5px"
    },
    tableBodyCell: {
        fontFamily: fontFamily.regularFont,
        fontSize: '14px',
        color: '#000'
    },
    tableHeadCell: {
        textAlign: "center",
        backgroundColor: palette.primary.main,
        color: palette.secondary.main,
        fontFamily: fontFamily.boldFont,
    },
}

export default function GenerateCode() {
    const dispatch = useDispatch();
    const allgenerateCodes = useSelector((state) => state.generateCode.generateCode);
    let userData = useSelector((state) => state.session.user);

    const [state, setState] = useState({
        generateCode: "",
        generateCodeError: false,
        startDate: null,
        startDateError: false,
        endDate: null,
        endDateError: false,
        generatedBy: "",
        generatedByError: false,
        generateCodeValidationError: false,
        isDialogOpen: false,
        status: "ACTIVE",
        mode: "ADD"
    });
    useEffect(() => {
        dispatch(fetchAllGeneratedCode());
    }, [dispatch]);

    const handleDialogOpen = () => {
        setState({
            ...state,
            isDialogOpen: true,
            mode: "ADD",
            generateCode: "",
            generateCodeError: false,
            startDate: null,
            startDateError: false,
            endDate: null,
            endDateError: null,
            generateCodeValidationError: false,

        })
    }
    // const handleEdit = (rowData) => {
    //     let formattedStartDate = null
    //     let formattedEndDate = null
    //     if (!!rowData.start_date) {
    //         let originalDate = rowData.start_date;
    //         let dateParts = originalDate.split("-");
    //         formattedStartDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    //     }
    //     if (!!rowData.end_date) {
    //         let originalDate = rowData.end_date;
    //         let dateParts = originalDate.split("-");
    //         formattedEndDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    //     }
    //     setState({
    //         ...state,
    //         isDialogOpen: true,
    //         id: rowData.id,
    //         mode: "EDIT",
    //         generateCode: rowData.code,
    //         startDate: moment(formattedStartDate, "DD/MM/YYYY"),
    //         startDateError: false,
    //         endDateError: false,
    //         endDate: moment(formattedEndDate, "DD/MM/YYYY"),
    //     })
    // }
    const handleDialogClose = () => {
        setState({ ...state, isDialogOpen: false })
    };
    const generateCodeAction = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const generateCode = Array.from({ length: 6 }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
        setState({
            ...state,
            generateCode,
            generateCodeError: false,
            generateCodeValidationError: false
        });
    };

    // const handleStatusChange = (e) => {
    //     setState({ ...state, status:e.target.value})
    // }

    const handleChange = (e) => {
        const value = e.target.value.toUpperCase();
        const regex = /^[A-Z0-9]*$/;
        if (regex.test(value)) {
            setState({ ...state, generateCode: value, generateCodeError: false, generateCodeValidationError: false });
        }
    };

    const handleSubmit = () => {
        let { generateCode, status, mode, id } = state;
        let isError = false;

        if (generateCode === '' || generateCode === null || generateCode === undefined) {
            setState((prevState) => ({ ...prevState, generateCodeError: true }))
            isError = true;
        }
        else if (generateCode === '' || generateCode === null || generateCode === undefined || generateCode.length < 6) {
            setState((prevState) => ({ ...prevState, generateCodeValidationError: true }))
            isError = true;
        }
        // if (startDate === '' || startDate === null || startDate === undefined) {
        //     setState((prevState) => ({ ...prevState, startDateError: true }))
        //     isError = true;
        // }
        // if (endDate === '' || endDate === null || endDate === undefined) {
        //     setState((prevState) => ({ ...prevState, endDateError: true }))
        //     isError = true;
        // }


        if (isError === false) {
            let obj = {};
            obj.code = generateCode.toString();
            // obj.start_date = startDate;
            // obj.end_date = endDate;
            obj.generated_by = userData.user.id
            obj.status = status;
            if (mode === 'ADD') {
                dispatch(createByGeneratedCode(obj, state, setState));
            }
            else {
                obj.id = id;
                dispatch(updateGeneratedCode(obj, state, setState));
            }
        }
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'code',
                header: 'Referral Code',
                grow: false,
                muiTableHeadCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'status',
                header: 'Status',
                grow: false,
                muiTableHeadCellProps: {
                    align: 'left',
                },
                Cell: ({ row }) => {
                    return (
                        <>
                            {
                                row?.original?.status && row.original.status === "ACTIVE" ? <Chip size="small" label="Active" style={customStyles.activeChip} /> : <Chip color="primary" size="small" label="Inactive" style={customStyles.inActiveChip} />
                            }
                        </>
                    )
                }
            },
            // {
            //     accessorKey: 'generated_by',
            //     header: 'Generate By',
            //     grow: false,
            //     minSize: 50,
            //     muiTableHeadCellProps: {
            //         align: 'left',
            //     },
            // },
            // {
            //     accessorKey: 'start_date',
            //     header: 'Start Date',
            //     grow: false,
            //     minSize: 50,
            //     Cell: ({ row }) => {
            //         return (
            //             <>
            //                 {row?.original?.start_date ? moment(row?.original?.start_date).format('DD/MM/YYYY') : "-"}
            //             </>
            //         )
            //     }
            // },
            // {
            //     accessorKey: 'end_date',
            //     header: 'End Date',
            //     grow: false,
            //     minSize: 50,
            //     Cell: ({ row }) => {
            //         return (
            //             <>
            //                 {row?.original?.end_date ? moment(row?.original?.end_date).format('DD/MM/YYYY') : "-"}
            //             </>
            //         )
            //     }
            // },
            // {
            //     accessorKey: 'action',
            //     header: 'Action',
            //     size: 150,
            //     Cell: ({ row }) => {
            //         return (
            //             <>
            //                 <Grid container >
            //                     <Grid item xs={12} style={{ marginRight: 10 }}>

            //                         <IconButton>
            //                             <Tooltip title={'Update Generate Code'}>
            //                                 <ModeEditIcon onClick={() => handleEdit(row.original)} />
            //                             </Tooltip>
            //                         </IconButton>

            //                     </Grid>
            //                 </Grid>
            //             </>
            //         )
            //     }
            // }

        ],
        [],
    );

    const tableView = useMaterialReactTable({
        columns,
        data: allgenerateCodes,
        enableColumnActions: false,
        enableRowNumbers: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        rowNumberDisplayMode: "static",
        muiTableBodyCellProps: {
            style: styles.tableBodyCell
        },
        muiTableHeadCellProps: {
            style: styles.tableHeadCell
        },
        initialState: { pagination: { pageSize: 10, pageIndex: 0 }, density: "compact" },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box>
                <Button startIcon={<WorkspacePremiumIcon />} size={"small"} sx={customStyles.buttonText} variant="contained" color="primary" onClick={() => handleDialogOpen()}>
                    Add Referral code
                </Button>
            </Box>
        )
    });
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Grid container >
                    <Typography style={customStyles.boldText}>
                        Referral
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MaterialReactTable table={tableView} />
                </Grid>
                <Grid item xs={12}>
                    <Dialog
                        open={state.isDialogOpen}
                        fullWidth
                        maxWidth="sm"
                    // TransitionComponent={Transition}
                    >
                        <DialogTitle style={{ display: 'flex' }} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography>Referral Code</Typography>
                            <IconButton onClick={() => handleDialogClose()} style={{ color: '#000' }}>
                                <DisabledByDefaultRoundedIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={3} justifyContent={'center'} alignItems={'center'} >
                                <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                                    <TextField value={state.generateCode} sx={{ marginTop: '10px' }} inputProps={{ maxLength: 6 }} fullWidth placeholder={"Enter the code"} onChange={(e) => handleChange(e)} size='small' error={state.generateCodeError} />
                                    {state.generateCodeError &&
                                        <FormHelperText style={styles.errorWarning}>Please enter the referral code</FormHelperText>
                                    }
                                    {state.generateCodeValidationError &&
                                        <FormHelperText style={styles.errorWarning}> Referral code must be 6 digits</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                                    <Typography style={{ justifyContent: 'center', textAlign: 'center' }}>
                                        (or)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Button
                                        autoFocus
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        sx={{ '&.MuiButton-root:hover': { bgcolor: "#2F7FFF" }, marginTop: 1 }}
                                        onClick={() => generateCodeAction()}
                                    >
                                        Generate
                                    </Button>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel style={customStyles.regularText}> Status </FormLabel>
                                        <RadioGroup
                                        value={state.status}
                                        defaultValue={state.status}
                                        onChange={(e) => handleStatusChange(e)}
                                        row
                                        >
                                            <FormControlLabel value="ACTIVE" control={<Radio/>} label="Active" />
                                            <FormControlLabel value="INACTIVE" control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField  sx={{ marginTop: '10px' }} fullWidth placeholder={"Generated By"} disabled value={userData?.user?.userProfile?.first_name} onChange={(e) => setState({ ...state, generatedBy: e.target.value.trimStart(), generatedByError: false })} size='small' error={state.generatedByError} />
                         
                            </Grid> */}
                                {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Start Date"
                                        value={state.startDate}
                                        disablePast
                                        format="DD/MM/YYYY"
                                        onChange={(date) =>
                                            setState({
                                                ...state,
                                                startDate: date,
                                                startDateError: false
                                            })
                                        }
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true,
                                                variant: 'outlined',
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                                inputProps: {
                                                    placeholder: 'DD/MM/YYYY',
                                                },
                                                sx: styles.dobTextField,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                                {state.startDateError &&
                                    <FormHelperText style={styles.errorWarning} >Please enter the start date</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="End Date"
                                        value={state.endDate}
                                        minDate={state.startDate}
                                        format="DD/MM/YYYY"
                                        onChange={(date) =>
                                            setState({
                                                ...state,
                                                endDate: date,
                                                endDateError: false
                                            })
                                        }
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true,
                                                variant: 'outlined',
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                                inputProps: {
                                                    placeholder: 'DD/MM/YYYY',
                                                },
                                                sx: styles.dobTextField,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                                {state.endDateError &&
                                    <FormHelperText style={styles.errorWarning}>Please enter the end date</FormHelperText>
                                }
                            </Grid> */}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus variant='contained' color='error' onClick={handleDialogClose}>Cancel</Button>
                            <Button autoFocus variant="contained" color="primary" sx={{ '&.MuiButton-root:hover': { bgcolor: "#2F7FFF" } }} onClick={() => handleSubmit()}>Submit</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </Grid>
    )
}   