import { createBrowserRouter } from 'react-router-dom';
import Root from './root';

// Create a browser router with route definitions
const router = createBrowserRouter([
  {
    path: "*",   // Catch-all route to match any path
    Component: Root   // Component to render for the matched route
  },
]);

// Export the router to be used in the application
export default router;