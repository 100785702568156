import API from '../../../src/api/accountApi';
import { fetchTestDrive } from '../../store/reducer/testDriveReducer';

/**
 * Fetch all test drive data
 * @function
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchAllTestDrive() {
    return async (dispatch) => {
        API.get('test-drive').then((response) => {
            if (response.status === 'success') {
                dispatch(fetchTestDrive(response.payload));
            }
        });
    }
}