import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button, Chip, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { editUserStatus, fetchRequestedUsers } from '../../store/actions/userAction';
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import { customStyles } from "../../helpers/customStyles";
import palette from '../../theme/palette';
import { fetchAllRoles } from "../../store/actions/roleAction";

const styles = {
    tableHeadCellProp: {
        textAlign: "center",
        backgroundColor: palette.primary.main,
        color: palette.secondary.main,
    },
    acceptButton: {
        '&.MuiButton-root:hover': { bgcolor: "#2F7FFF" }
    },
    buttonPosition: {
        position: 'absolute',
        right: 20,
        top: 8,
        color: 'common.black',
    }
}

const RequestedUser = () => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user.requestedUser);
    const roles = useSelector((state) => state.role.roles);

    let filteredRole = roles.filter((item) => item.is_internal_role === false);

    const [state, setState] = useState({
        open: false,
        selectedUser: [],
        screen: 'REQUESTED_USERS',
        selectedRoleId: ""
    });

    const [pagination] = useState({
        pageIndex: 0,
        pageSize: 10, //customize the default page size
    });

    useEffect(() => {
        dispatch(fetchAllRoles());
        dispatch(fetchRequestedUsers(pagination.pageIndex, pagination.pageSize));
    }, [dispatch, pagination.pageIndex, pagination.pageSize])

    const dialogAction = useCallback((rowData) => {
        setState({
            ...state,
            open: true,
            selectedUser: rowData,
            selectedRoleId: rowData.userRoles[0]['role'].id
        });
    }, [state, setState]);
    const handleDialogClose = () => {
        setState({ ...state, open: false })
    };

    const updateUserStaus = (status) => {
        let user = {};
        if (status === 'REJECTED') {
            user.id = state.selectedUser?.id;
            user.status = status;
        } else {
            user.id = state.selectedUser?.id;
            user.role_id = state.selectedRoleId;
            user.status = status;
        }
        setState({ ...state, open: false })
        dispatch(editUserStatus(user, state, setState, pagination))
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'userProfile.first_name',
                header: 'Name',
                size: 150,
            },
            {
                accessor: 'role',
                header: 'Role',
                size: 120,
                Cell: ({ row }) => {
                    const roleName = !!row.original.userRoles && !!row.original.userRoles[0].role && row.original.userRoles[0].role.name
                    return (
                        <>
                            {roleName}
                        </>
                    )
                }
            },
            {
                accessorKey: 'userProfile.phone_number',
                header: 'Phone Number',
                size: 150,
            },
            {
                accessorKey: 'userProfile.email',
                header: 'Email',
                size: 150,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 150,
                Cell: ({ row }) => (
                    <>
                        {
                            !!row.original.status && row.original.status === "ACTIVE" ? <Chip size="small" label="Active" style={customStyles.activeChip} /> : <Chip color="primary" size="small" label="Inactive" style={customStyles.inActiveChip} />
                        }
                    </>
                )
            },
            {
                accessorKey: 'action',
                header: 'Action',
                size: 150,
                Cell: (rowData) => (
                    <Button onClick={() => dialogAction(rowData.row.original)}>View</Button>
                    // <IconButton onClick={() => handleEdit(rowData.row.original)}></IconButton>
                )
            }
        ],
        [dialogAction],
    );

    const tableView = useMaterialReactTable({
        columns,
        data: users,
        enableDensityToggle: false,
        initialState: { density: 'compact', },
        enableFullScreenToggle: false,
        enableRowNumbers: true,
        rowNumberDisplayMode: "static",
        muiTableHeadCellProps: {
            style: styles.tableHeadCellProp
        },
        // rowCount: 10
    });

    const roleSelectAction = (e) => {
        setState({ ...state, selectedRoleId: e.target.value })
    };

    return (
        <Grid container justifyContent={'center'} spacing={2}>
            <Grid item xs={12}>
                <MaterialReactTable table={tableView} />
            </Grid>
            <Dialog open={state.open} maxWidth={"sm"}>
                <DialogTitle>
                    Confirmation !
                </DialogTitle>
                <IconButton
                    onClick={() => handleDialogClose()}
                    sx={styles.buttonPosition}
                >
                    <DisabledByDefaultRoundedIcon sx={{ width: 30, height: 30 }} />
                </IconButton>
                <DialogContent dividers >
                    <Grid container spacing={3} justifyContent={'flex-start'} alignItems={'center'}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: '1.25em' }}>Do you want to accept this user?</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}  >
                            <TextField value={state.selectedUser?.userProfile?.first_name} sx={{ "& .MuiInputBase-input.Mui-disabled": { backgroundColor: '#E0E0E0' }, }} placeholder={"First name"} disabled fullWidth size='small' />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.selectedUser?.userProfile?.last_name} sx={{ "& .MuiInputBase-input.Mui-disabled": { backgroundColor: '#E0E0E0' }, }} placeholder={"Last name"} disabled fullWidth size='small' />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.selectedUser?.userProfile?.phone_number} sx={{ "& .MuiInputBase-input.Mui-disabled": { backgroundColor: '#E0E0E0' }, }} placeholder={"phone number"} disabled fullWidth size='small' />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField value={state.selectedUser?.userProfile?.email} sx={{ "& .MuiInputBase-input.Mui-disabled": { backgroundColor: '#E0E0E0' }, }} placeholder={"Email"} fullWidth disabled size='small' />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl fullWidth>
                                <InputLabel>Role</InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    size='small'
                                    displayEmpty
                                    label="role"
                                    value={state.selectedRoleId}
                                    sx={{ marginTop: '10px' }}
                                    onChange={roleSelectAction}
                                >
                                    {!!filteredRole && filteredRole.slice().sort((a, b) => a.name.localeCompare(b.name))
                                        .map((item) =>
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='error' style={customStyles.cancelButtonText} onClick={() => updateUserStaus('REJECTED')}>
                        Reject
                    </Button>
                    <Button autoFocus variant="contained" color="primary" sx={styles.acceptButton} onClick={() => updateUserStaus('ACTIVE')}>
                        Accept
                    </Button>
                </DialogActions>
            </ Dialog>
        </Grid>
    )
}

export default RequestedUser;