import { fetchUsers, addUser, updateUser, fetchUserMetrics, getActiveUsers, getRequestedUsers, getRejectedUsers } from "../reducer/userReducer";
import API from '../../api/accountApi';
import { displayAlert } from "../reducer/alertReducer";

/**
 * Fetch all users
 * @function
 * @param {number} offset - The offset for pagination
 * @param {number} limit - The limit for pagination
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchAllUsers(offset, limit) {
    return async (dispatch) => {
        API.get(`user?offset=${offset}&limit=${limit}`).then((response) => {
            if (response.status === "success") {
                dispatch(fetchUsers(response.payload));
            }
        });
    };
}

/**
 * Fetch active users
 * @function
 * @param {number} offset - The offset for pagination
 * @param {number} limit - The limit for pagination
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchActiveUsers(offset, limit) {
    return async (dispatch) => {
        API.get(`user?offset=${offset}&limit=${limit}&status=ACTIVE`).then((response) => {
            if (response.status === "success") {
                dispatch(getActiveUsers(response.payload));
                dispatch(fetchAllUserMetrics());
            }
        });
    };
}

/**
 * Fetch requested users
 * @function
 * @param {number} offset - The offset for pagination
 * @param {number} limit - The limit for pagination
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchRequestedUsers(offset, limit) {
    return async (dispatch) => {
        API.get(`user?offset=${offset}&limit=${limit}&status=REQUESTED`).then((response) => {
            if (response.status === "success") {
                dispatch(getRequestedUsers(response.payload));
                dispatch(fetchAllUserMetrics());
            }
        });
    };
}

/**
 * Fetch rejected users
 * @function
 * @param {number} offset - The offset for pagination
 * @param {number} limit - The limit for pagination
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchRejectedUsers(offset, limit) {
    return async (dispatch) => {
        API.get(`user?offset=${offset}&limit=${limit}&status=REJECTED`).then((response) => {
            if (response.status === "success") {
                dispatch(getRejectedUsers(response.payload));
                dispatch(fetchAllUserMetrics());
            }
        });
    };
}

/**
 * Fetch all user metrics
 * @function
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchAllUserMetrics() {
    return async (dispatch) => {
        API.get(`user/metrics/count`).then((response) => {
            if (response.status === "success") {
                dispatch(fetchUserMetrics(response.payload));
            }
        });
    };
}

/**
 * Add a new user
 * @function
 * @param {Object} data - The data for the new user
 * @param {Object} state - The current state of the component
 * @param {Function} setState - The function to update the state of the component
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function addNewUser(data, state, setState) {
    return async (dispatch) => {
        API.post('user', data).then((response) => {
            if (response.status === 'success') {
                dispatch(addUser(response.payload));
                dispatch(fetchAllUserMetrics());
                setState({ ...state, isDialogOpen: false })
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "success", alertMessage: "User has been added successfully" }));
            }
            else {
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "error", alertMessage: "Email already Exists" }))
            }
        });
    }
}

/**
 * Edit an existing user
 * @function
 * @param {Object} data - The data for the user to be updated, including the user ID
 * @param {Object} state - The current state of the component
 * @param {Function} setState - The function to update the state of the component
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function editUser(data, state, setState) {
    return async (dispatch) => {
        API.put(`user/${data.id}`, data).then(async (response) => {
            if (response.status === 'success') {
                dispatch(updateUser(response.payload))
                setState({ ...state, isDialogOpen: false })
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "success", alertMessage: "User has been updated successfully" }));
            }
        });
    }
};

/**
 * Edit the status of a user
 * @function
 * @param {Object} data - The data for updating the user status, including the user ID
 * @param {Object} state - The current state of the component
 * @param {Function} setState - The function to update the state of the component
 * @param {Object} pagination - The pagination details, including pageIndex and pageSize
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function editUserStatus(data, state, setState, pagination) {
    return async (dispatch) => {
        API.patch(`user/consent-request/${data.id}`, data).then(async (response) => {
            if (response.status === 'success') {
                dispatch(updateUser(response.payload));
                dispatch(fetchAllUserMetrics());
                if (state.screen === "REQUESTED_USERS") {
                    dispatch(fetchRequestedUsers(pagination.pageIndex, pagination.pageSize));
                } else if (state.screen === "REJECTED_USERS") {
                    dispatch(fetchRejectedUsers(pagination.pageIndex, pagination.pageSize));
                }
                setState({ ...state, open: false })
            }
        });
    }
};

/**
 * Reset user password
 * @function
 * @param {Object} data - The data for resetting the password, including the new password and verification code
 * @param {Function} navigate - The function to navigate to different routes
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function resetPassword(data, navigate) {
    return async (dispatch) => {
        API.post('user/reset-password', data).then(async (response) => {
            if (response.status === 'success') {
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "success", alertMessage: "Password changed successfully" }));
                navigate('/');
            } else {
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "error", alertMessage: "Please enter the exact Code" }));
            }
        });
    }
}

/**
 * Change user password
 * @function
 * @param {Object} data - The data for changing the password, including the old and new passwords
 * @param {Function} handleDialogClose - A function to close the dialog after password change
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function changePassword(data, handleDialogClose) {
    return async (dispatch) => {
        API.post('user/change-password', data).then(async (response) => {
            if (response.status === 'success') {
                dispatch(displayAlert({
                    "openAlert": true, alertSeverity: "success", alertMessage:
                        "Password changed successfully"
                }));
                // localStorage.clear()
                // navigate('/');
                handleDialogClose();

            } else {
                dispatch(displayAlert({
                    "openAlert": true, alertSeverity: "error", alertMessage:
                        "Please enter the exact Code"
                }));
            }
        });
    }

}
