import { useState, useEffect } from 'react';
import { Drawer, ListItemButton, ListItemText, ListItemIcon, List, Divider, ListItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PropTypes from "prop-types";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useSelector } from 'react-redux';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import GridViewIcon from '@mui/icons-material/GridView';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const sideBarMenu = [
  {
    screen: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    screen: 'Users',
    path: '/users',
    icon: <SupervisorAccountIcon />
  },
  {
    screen: 'Test Drive',
    path: '/test-drives',
    icon: <AirportShuttleIcon />
  },
  {
    screen: 'Screens',
    path: '/screens',
    icon: <GridViewIcon />
  },
  {
    screen: 'Roles',
    path: '/roles',
    icon: <AdminPanelSettingsIcon />
  },
  {
    screen: 'Referral',
    path: '/generate',
    icon: <WorkspacePremiumIcon />
  }
]

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
};

function Sidebar(props) {
  const navigate = useNavigate();
  const drawerWidth = 250;
  const theme = useTheme();
  const [selectedButton, setSelectedButton] = useState('');
  const [hoverKey, setHoverKey] = useState(false);
  const [hoverKeyScreen, setHoverKeyScreen] = useState('');

  let roleData = useSelector((state) => state.session.user).role;
  let sideBarMenuBasedOnRole = sideBarMenu;

  const screenChildren = (childrenArray, screen) => {
    let screenPresent = false;

    for (let x of childrenArray) {
      screenPresent = x.screen === screen ? true : false;
    }
    return screenPresent;
  }

  if (!!roleData) {
    sideBarMenuBasedOnRole = sideBarMenu.filter((sideMenu) =>
      roleData?.permission.some((permission) => (sideMenu.screen === 'Settings' ? screenChildren(sideMenu.children, permission.screen) : permission.screen === sideMenu.screen) && permission.list === true)
    );
  }

  useEffect(() => {
    const savedActiveMenu = localStorage.getItem('screen') || sideBarMenuBasedOnRole[0]["screen"];
    setSelectedButton(savedActiveMenu);
  }, [selectedButton, sideBarMenuBasedOnRole]);

  const buttonAction = (text) => {
    setSelectedButton(text.screen);
    localStorage.setItem('screen', text.screen);
    navigate(text.path);
  };

  const onHover = (screen) => {
    setHoverKeyScreen(screen);
    setHoverKey(true);
  };

  const exitHover = () => {
    setHoverKey(false);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          zIndex: theme.zIndex.drawer - 1,
          top: 144,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: "linear-gradient(90deg, #A4D3E6 0%, #60b3e0 100%)"
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.isOpen}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between' }}>
        <div >
          <Divider />
          <List>
            {!!sideBarMenuBasedOnRole && sideBarMenuBasedOnRole.map((text, index) => (
              text.isAccordion ? (
                <Accordion key={text.screen} sx={{ backgroundColor: 'white', boxShadow: 'none', margin: '4px', padding: '0px' }}>
                  <AccordionSummary sx={{ padding: '0px 10px' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${text.screen}-content`}
                    id={`${text.screen}-header`}
                  >
                    <ListItemIcon>{text.icon}</ListItemIcon>
                    <ListItemText primaryTypographyProps={styles.regularText}>{text.screen}</ListItemText>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '1px 4px 1px 15px' }}>
                    <List>
                      {text.children.map((child) => (
                        <ListItem key={child.screen} disablePadding>
                          <ListItemButton
                            onMouseEnter={() => onHover(child.screen)}
                            onMouseLeave={() => exitHover()}
                            style={{
                              backgroundColor: selectedButton === child.screen ? '#3FA2F6' : hoverKey && hoverKeyScreen === child.screen ? 'lightgray' : "#ffff",
                              color: selectedButton === child.screen ? '#fff' : '#000',
                              borderRadius: '5px',
                            }}
                            onClick={() => buttonAction(child)}
                          >
                            <ListItemIcon>{child.icon}</ListItemIcon>
                            <ListItemText primaryTypographyProps={styles.regularText} primary={child.screen} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <ListItem key={text.screen} disablePadding>

                  <ListItemButton
                    onMouseEnter={() => onHover(text.screen)}
                    onMouseLeave={() => exitHover()}
                    style={{
                      backgroundColor: selectedButton === text.screen ? '#3FA2F6' : hoverKey && hoverKeyScreen === text.screen ? 'lightgray' : "#fff",
                      color: selectedButton === text.screen ? '#fff' : '#000',
                      borderRadius: '5px',
                      margin: 4,
                      padding: '10px'
                    }}
                    onClick={() => buttonAction(text)}
                  >
                    <ListItemIcon>
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={styles.regularText} primary={text.screen} />
                  </ListItemButton>
                </ListItem>
              )
            ))}
          </List>
        </div>
      </div>
    </Drawer>
  )
}

const styles = {
  boldText: {
    fontFamily: "Open-Sans-Bold",
    fontSize: "18px",
    fontWeight: "950"
  },
  regularText: {
    fontFamily: "Open-Sans-Regular",
    fontSize: "16px",
    fontWeight: '600',
  }
}

export default Sidebar;