import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts'
import { fetchDashboardData } from '../../store/actions/dashboardAction';
import AnalyticImage from '../../assets/data-analytics.png';
import TestDrive from '../../assets/test-drive.png';
import Distance from '../../assets/distance.png';
import TimeTaken from '../../assets/time-left.png';
import palette from '../../theme/palette';
import { fontFamily } from '../../helpers/customStyles';


export default function Dashboard() {
    let dispatch = useDispatch();
    const dashboardData = useSelector(state => state.dashboard.dashboardData);

    const [series, setSeries] = useState([{
        name: 'Inflation',
        data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
    }]);
    const [options] = useState({
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val;
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            position: 'top',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                formatter: function (val) {
                    return val;
                }
            }
        },
        title: {
            text: 'Test Drive Monthly Basis',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
                color: '#444'
            }
        }
    },);
    // const [chartSeries, setChartSeries] = useState(
    //     [45, 10]
    //     // [70]
    // );
    const [chartOptions] = useState({
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['Internal Users', 'Clients'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
    },);
    const [widgets] = useState(['TDC', 'UTDA', 'UTHD', 'TNU', 'TTD', 'TTW', 'TTE', 'INCNU', 'TDMB'])

    useEffect(() => {
        let data = {};
        data.widgets = widgets;
        dispatch(fetchDashboardData(data, setSeries))
    }, [dispatch, widgets]);

    const styles = {
        cardView: {
            backgroundColor: palette.secondary.main,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 15,
            boxShadow: "0px 0px 16px 4px rgba(0,0,0,0.1)"
        },
        graphView: {
            backgroundColor: palette.secondary.main,
            borderRadius: 5,
            padding: 10,
            boxShadow: "0px 0px 16px 4px rgba(0,0,0,0.1)"
        },
        pieView: {
            backgroundColor: palette.secondary.main,
            borderRadius: 5,
            boxShadow: "0px 0px 16px 4px rgba(0,0,0,0.1)",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        headerText: {
            textAlign: 'right',
            fontSize: '16px',
            fontWeight: 'bolder',
            fontFamily: fontFamily.boldFont,
        },
        valueText: {
            textAlign: 'right',
            fontSize: '16px',
            fontWeight: 'bolder',
            color: 'grey',
            fontFamily: fontFamily.regularFont,
        }
    }

    return (
        <div>
            <Grid container rowGap={5} justifyContent={'space-between'}>
                <Grid item xs={12} sm={5} md={5} lg={2.7} xl={2.7} style={styles.cardView}>
                    <Grid item xs={4}>
                        <img src={AnalyticImage} alt='no' height={50} width={50} />
                    </Grid>
                    <Grid item xs={8} >
                        <Typography style={styles.headerText}>Test Engineers</Typography>
                        <Typography style={styles.valueText}>{!!dashboardData ? dashboardData.TTE : '0'}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={2.7} xl={2.7} style={styles.cardView}>
                    <Grid item xs={3}>
                        <img src={TestDrive} alt='no' height={50} width={50} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography style={styles.headerText}>Test Drive</Typography>
                        <Typography style={styles.valueText}>{!!dashboardData ? dashboardData.TTD : '0'}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={2.7} xl={2.7} style={styles.cardView}>
                    <Grid item xs={3}>
                        <img src={Distance} alt='no' height={50} width={50} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography style={styles.headerText}>Distance</Typography>
                        <Typography style={styles.valueText}>{`${!!dashboardData ? dashboardData.TDC : '0'} Km`}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={2.7} xl={2.7} style={styles.cardView}>
                    <Grid item xs={3}>
                        <img src={TimeTaken} alt='no' height={50} width={50} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography style={styles.headerText}>Time Taken</Typography>
                        <Typography style={styles.valueText}>{`${!!dashboardData ? dashboardData.TTW : '0'} hr`}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7.5} style={styles.graphView}>
                    <ReactApexChart options={options} series={series} type="bar" height={350} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} style={styles.pieView}>
                    <Typography style={styles.headerText}>Users</Typography>
                    {!!dashboardData &&
                        <ReactApexChart options={chartOptions}
                            series={dashboardData.INCNU?.length > 0 ? [...dashboardData.INCNU] : [0, 0]}
                            type="pie" width={370}
                        />
                    }
                </Grid>
            </Grid>
        </div>
    )
}