import React from 'react';
import { Snackbar, Grid, Typography } from '@mui/material';
import { displayAlert } from '../../src/store/reducer/alertReducer';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from "react-redux";

// CustomSnackbar component to display notifications
const CustomSnackbar = ({ vertical, horizontal }) => {
    const dispatch = useDispatch();

    const { alertSeverity, alertMessage, openAlert } = useSelector((state) => state.alert);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(displayAlert({ openAlert: false, alertSeverity: "", alertMessage: "" }));
    };

    const SlideRightToLeft = (props) => {
        return <Slide {...props} direction="left" />;
    };

    const CustomContent = () => (
        <div style={styles.container}>
            <Grid container spacing={5} sx={{ justifyContent: 'center', alignItems: 'center', height: '5em' }}>
                <Grid item xs={12} md={12} lg={12} sm={12} >
                    {/* <Typography sx={styles.notificationTitle}>INFORMATION </Typography> */}
                    <Typography sx={styles.notificationSubTitle}> {alertMessage}</Typography>
                </Grid>
            </Grid>
        </div>
    );

    return (
        <Snackbar
            TransitionComponent={SlideRightToLeft}
            anchorOrigin={{ vertical, horizontal }}
            open={openAlert}
            onClose={(event, reason) => handleCloseSnackbar(event, reason)}
            autoHideDuration={2500}
            key={vertical + horizontal}
        >
            <MuiAlert elevation={1} variant='filled' severity={alertSeverity === "success" ? "success" : alertSeverity === "error" ? "error" : alertSeverity === "info" ? "info" : "warning"} icon={false} sx={{ width: '100%' }}>
                <CustomContent />
            </MuiAlert>
        </Snackbar>
    );
}

const styles = {
    container: { justifyContent: "center", alignItems: "center" },
    notificationTitle: { fontSize: { xs: 12, md: 12, sm: 12, lg: 16, xl: 16 }, color: "#fff" },
    notificationSubTitle: { fontSize: { xs: 12, md: 12, sm: 12, lg: 16, xl: 16 }, color: "#fff", fontWeight: 'bold', },
};

export default CustomSnackbar;