import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from '../src/router/index';
import { Provider } from 'react-redux';
import store from './store';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import CustomSnackbar from './components/customSnackBar';
import { refreshAction } from './store/actions/sessionAction';

export default function App() {

  useEffect(() => {
    let token =
      localStorage !== undefined && localStorage.getItem("accessToken");
    if (token !== null) {
      store.dispatch(refreshAction())
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CustomSnackbar vertical="top" horizontal="center" />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  );
}