// Importing the palette module from the theme
import palette from "../theme/palette";

// Define font families
export const fontFamily = {
    regularFont: "Open-Sans-Regular",
    boldFont: "Open-Sans-Bold",
}

// Define custom styles
export const customStyles = {
    boldText: {
        fontFamily: fontFamily.boldFont,
        fontSize: "19px",
        fontWeight: "950"
    },
    regularText: {
        fontFamily: fontFamily.regularFont,
        fontSize: "16px",
        color: 'grey',
        fontWeight: "600",
        '&:hover': {
            backgroundColor: "rgb(47, 127, 255,0.2)"
        }
    },
    activeText: {
        fontFamily: fontFamily.regularFont,
        color: '#000',
        fontSize: "16px",
        fontWeight: "600",
        '&:hover': {
            backgroundColor: "rgb(47, 127, 255,0.2)"
        }
    },
    buttonText: {
        fontFamily: fontFamily.regularFont,
        fontSize: "14px",
        fontWeight: "600"
    },
    cancelButtonText: {
        fontFamily: fontFamily.regularFont,
        fontSize: "14px",
        fontWeight: "600"
    },
    adminChip: {
        fontFamily: fontFamily.regularFont,
        borderRadius: 6
    },
    clientChip: {
        fontFamily: fontFamily.regularFont,
        borderRadius: 6,
        paddingRight: 5,
        paddingLeft: 5
    },
    activeChip: {
        color: palette.chipColor.main,
        backgroundColor: palette.chipColor.backgroundColor,
        fontFamily: fontFamily.regularFont,
        borderRadius: 6,
        fontWeight: 'bold',
        paddingRight: 5,
        paddingLeft: 5
    },
    inActiveChip: {
        color: palette.InActiveChipColor.main,
        backgroundColor: palette.InActiveChipColor.backgroundColor,
        fontFamily: fontFamily.regularFont,
        fontWeight: 'bold',
        borderRadius: 6
    },
    textStyle: {
        fontFamily: fontFamily.regularFont,
        fontSize: "16px",
        fontWeight: "normal",
    },
    infoTextStyle: {
        fontFamily: fontFamily.regularFont,
        fontSize: "16px",
        fontWeight: 'normal',
        textAlign: 'justify'
    }
}
