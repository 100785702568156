import { Grid, Typography } from '@mui/material';
import LogoTvs from '../../assets/logo_tvs.webp';
import { customStyles, fontFamily } from '../../helpers/customStyles';

export default function TermsAndConditions() {

    const styles = {
        headerText: {
            fontFamily: "Arial, Helvetica, sans-serif",
            fontSize: "28px",
            fontWeight: "600",
            color: '#0D4576',
        },
        mainHeaderText: {
            fontSize: '24px',
            color: '#519BCB',
            textAlign: 'center',
            fontFamily: fontFamily.boldFont
        },
        subHeaderTextBlack: {
            fontSize: '18px',
            fontFamily: fontFamily.boldFont
        },
        subHeaderTextBlue: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#519BCB',
            fontFamily: fontFamily.regularFont
        },
        infoTextStyleRed: {
            fontFamily: fontFamily.regularFont,
            fontSize: "16px",
            fontWeight: 'normal',
            color: 'red'
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ position: 'fixed', top: 0, backgroundColor: '#ffff', width: '100%', zIndex: 10000000 }}>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}><img src={LogoTvs} alt='logo' style={{}} height={130} width={160} /></Grid>
                    <Grid item xs={8} ><Typography style={styles.headerText} >SUNDARAM BRAKE LININGS LIMITED</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', rowGap: 20, marginRight: '10%', marginLeft: '10%', position: 'relative', top: 150, paddingBottom: '5%' }}>
                <Typography style={styles.mainHeaderText}>Terms and Conditions</Typography>
                <Typography style={styles.subHeaderTextBlack}>Sundaram Brake Linings Limited</Typography>
                <Typography style={customStyles.infoTextStyle}>Sundaram Brake Linings Limited (SBL), a company incorporated under the Indian
                    Companies Act, 1956 and having its Registered Office at Padi, Chennai 600 050, India
                    built the Brake Trail App as an R&amp;D data acquisition App. This SERVICE is provided by
                    SBL at no cost and is intended for use as is.</Typography>
                <Typography style={customStyles.infoTextStyle}>By downloading or using the app, these terms will automatically apply to you &#8208; you should
                    make sure therefore that you read them carefully before using the app. You&apos;re not allowed
                    to copy, or modify the app, any part of the app, or our trademarks in any way. You&apos;re not
                    allowed to attempt to extract the source code of the app, and you also shouldn&apos;t try to
                    translate the app into other languages, or make derivative versions. The app itself, and all
                    the trade marks, copyright, database rights and other intellectual property rights related to
                    it, still belong to SBL.</Typography>
                <Typography style={customStyles.infoTextStyle}>SBL is committed to ensuring that the app is as useful and efficient as possible. For that
                    reason, we reserve the right to make changes to the app or to charge for its services, at
                    any time and for any reason. We will never charge you for the app or its services without
                    making it very clear to you exactly what you&apos;re paying for.</Typography>
                <Typography style={customStyles.infoTextStyle}>The Brake Trail app stores and processes personal data that you have provided to us, in
                    order to provide our Service. It&apos;s your responsibility to keep your phone and access to the
                    app secure. We therefore recommend that you do not jailbreak or root your phone, which
                    is the process of removing software restrictions and limitations imposed by the official
                    operating system of your device. It could make your phone vulnerable to
                    malware/viruses/malicious programs, compromise your phone&apos;s security features and it
                    could mean that this app won&apos;t work properly or at all.</Typography>
                <Typography style={customStyles.infoTextStyle}>The app does use third party services that declare their own Terms and Conditions.</Typography>
                <Typography style={customStyles.infoTextStyle}>Terms and Conditions of third party service providers used by the app are bound to
                    owners.</Typography>
                <Typography style={customStyles.infoTextStyle}>1. Google Play Services.</Typography>
                <Typography style={customStyles.infoTextStyle}>2. Amazon Web Services</Typography>
                <Typography style={customStyles.infoTextStyle}>You should be aware that there are certain things that SBL will not take responsibility for.
                    Certain functions of the app will require the app to have an active internet connection. The
                    connection can be Wi-Fi, or provided by your mobile network provider, but SBL cannot
                    take responsibility for the app not working at full functionality if you don&apos;t have access to
                    Wi-Fi, and you don&apos;t have any of your data allowance left.</Typography>
                <Typography style={customStyles.infoTextStyle}>If you&apos;re using the app outside of an area with Wi-Fi, you should remember that your terms
                    of the agreement with your mobile network provider will still apply. As a result, you may be
                    charged by your mobile provider for the cost of data for the duration of the connection
                    while accessing the app, or other third party charges. In using the app, you&apos;re accepting
                    responsibility for any such charges, including roaming data charges if you use the app
                    outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you&apos;re using the app, please be aware
                    that we assume that you have received permission from the bill payer for using the app.</Typography>
                <Typography style={customStyles.infoTextStyle}>Along the same lines, SBL cannot always take responsibility for the way you use the app
                    i.e. You need to make sure that your device stays charged &#8208; if it runs out of battery and
                    you can&apos;t turn it on to avail the Service, SBL cannot accept responsibility.</Typography>
                <Typography style={customStyles.infoTextStyle}>With respect to SBL&apos;s responsibility for your use of the app, when you&apos;re using the app, it&apos;s
                    important to bear in mind that although we endeavour to ensure that it is updated and
                    correct at all times, we do rely on third parties to provide information to us so that we can
                    make it available to you. SBL accepts no liability for any loss, direct or indirect, you
                    experience as a result of relying wholly on this functionality of the app.</Typography>
                <Typography style={customStyles.infoTextStyle}>At some point, we may wish to update the app. The app is currently available on Android &#8208;
                    the requirements for system (and for any additional systems we decide to extend the
                    availability of the app to) may change, and you&apos;ll need to download the updates if you want
                    to keep using the app. SBL does not promise that it will always update the app so that it is
                    relevant to you and/or works with the Android version that you have installed on your
                    device. However, you promise to always accept updates to the application when offered to
                    you, we may also wish to stop providing the app, and may terminate use of it at any time
                    without giving notice of termination to you. Unless we tell you otherwise, upon any
                    termination, (a) the rights and licenses granted to you in these terms will end; (b) you must
                    stop using the app, and (if needed) delete it from your device.</Typography>
                <Typography style={styles.subHeaderTextBlue}>Changes to This Terms and Conditions:</Typography>
                <Typography style={customStyles.infoTextStyle}>We may update our Terms and Conditions from time to time. Thus, you are advised to
                    review this page periodically for any changes. We will notify you of any changes by posting
                    the new Terms and Conditions on this page.</Typography>
                <Typography style={customStyles.infoTextStyle}>This Privacy Policy was last updated Sep.1, 2024.</Typography>
                <Typography style={styles.subHeaderTextBlue}>Contact Us:</Typography>
                <Typography style={customStyles.infoTextStyle}>If you have any questions or suggestions about our Terms and Conditions, do not hesitate
                    to contact us at privacy@tvssbl.com</Typography>
            </Grid>
            {/* <Grid container style={{ position: 'fixed', bottom: 0, display: 'flex', justifyContent: 'flex-end', backgroundColor: '#0A3058', padding: 5 }}>
                <Grid item xs={5} >
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={3}>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/privacy-policy`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Privacy policy</a></Typography>
                        </Grid>
                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/about`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}> About</a></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid container style={{ position: 'fixed', bottom: 0, justifyContent: 'flex-end', backgroundColor: '#0A3058' }}>
                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                    <Grid container justifyContent={'space-around'}>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/privacy-policy`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Privacy policy</a></Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/about`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>About</a></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
