import { Grid, Typography } from '@mui/material';
import LogoTvs from '../../assets/logo_tvs.webp';
import { customStyles, fontFamily } from '../../helpers/customStyles';

export default function About() {

    const styles = {
        headerText: {
            fontFamily: "Arial, Helvetica, sans-serif",
            fontSize: "28px",
            fontWeight: "600",
            color: '#0D4576',
        },
        mainHeaderText: {
            fontSize: '24px',
            color: '#519BCB',
            textAlign: 'center',
            fontFamily: fontFamily.boldFont
        },
        subHeaderTextBlack: {
            fontSize: '18px',
            fontFamily: fontFamily.boldFont
        },
        subHeaderTextBlue: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#519BCB',
            fontFamily: fontFamily.regularFont
        },
        infoTextStyleRed: {
            fontFamily: fontFamily.regularFont,
            fontSize: "16px",
            fontWeight: 'normal',
            color: 'red'
        }
    }

    return (
        <Grid container >
            <Grid item xs={12} style={{ position: 'fixed', top: 0, backgroundColor: '#ffff', width: '100%', zIndex: 10000000 }}>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}><img src={LogoTvs} alt='logo' height={130} width={160} /></Grid>
                    <Grid item xs={8}><Typography style={styles.headerText} >SUNDARAM BRAKE LININGS LIMITED</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', rowGap: 20, marginRight: '10%', marginLeft: '10%', position: 'relative', top: 150, paddingBottom: '5%' }}>
                <Typography style={styles.mainHeaderText}>About this App</Typography>
                <Typography style={styles.subHeaderTextBlack}>Brake Trail</Typography>
                <Typography style={customStyles.infoTextStyle}>Brake Trail App is a part of the DEFCON (Drivers Experience of Friction Condition) System
                    developed at SBL.</Typography>
                <Typography style={customStyles.infoTextStyle}>DEFCON is an integrated hardware, software, and analysis platform that is unobtrusively
                    deployable on any vehicle in less than a minute for real time data acquisition. This enables
                    accurate and objective friction recommendations utilising the actual vehicle duty cycles.</Typography>
                <Typography style={customStyles.infoTextStyle}>The Brake Trail App collects data from the sensors connected to the customised mobile
                    device and submits to SBL database for analysis.</Typography>
                <Typography style={customStyles.infoTextStyle}>Only authorised users can download Brake Trail App using the unique referral code
                    provided for this purpose.</Typography>
            </Grid>
            {/* <Grid container style={{ position: 'fixed', bottom: 0, display: 'flex', justifyContent: 'flex-end', backgroundColor: '#0A3058', padding: 5 }}>
                <Grid item xs={5} >
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={3}>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/privacy-policy`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Privacy policy</a></Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/terms-and-conditions`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}> Terms of Service</a></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid container style={{ position: 'fixed', bottom: 0, justifyContent: 'flex-end', backgroundColor: '#0A3058' }}>
                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                    <Grid container justifyContent={'space-around'}>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/privacy-policy`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Privacy policy</a></Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/terms-and-conditions`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Terms of Service</a></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
