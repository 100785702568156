import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { fetchAllTestDrive } from '../../store/actions/testDriveAction';
import { useSelector, useDispatch } from 'react-redux';
import { customStyles, fontFamily } from '../../helpers/customStyles';
import moment from 'moment';
import palette from '../../theme/palette';

const styles = {
    tableBodyCell: {
        fontFamily: fontFamily.regularFont,
        fontSize: '14px',
        color: '#000',
        height: 60
    },
    tableHeadCell: {
        textAlign: "center",
        backgroundColor: palette.primary.main,
        color: palette.secondary.main,
        fontFamily: fontFamily.boldFont
    }
}

export default function TestDrive() {
    const dispatch = useDispatch();
    const testDrive = useSelector((state) => state.testDrive.testDrive);

    React.useEffect(() => {
        dispatch(fetchAllTestDrive())
    }, [dispatch]);

    const columns = React.useMemo(() => [

        {
            accessorKey: 'user_name', //access nested data with dot notation
            header: 'User Name',
            size: 100,
        },
        {
            accessorKey: 'date_of_test_drive',
            header: 'Date',
            size: 100,
            Cell: ({ row }) => {
                return (
                    <>
                        {row?.original?.date_of_test_drive ? moment(row?.original?.date_of_test_drive).format('DD/MM/YYYY') : "-"}
                    </>
                )
            }
        },
        {
            accessorKey: 'start_time',
            header: 'Start Time',
            size: 100,
            Cell: ({ row }) => {
                return (
                    <>
                        {!!row?.original?.start_time ? moment(row?.original?.start_time).format('LT') : "-"}
                    </>
                )
            }
        },
        {
            accessorKey: 'end_time',
            header: 'End Time',
            size: 100,
            Cell: ({ row }) => {
                return (
                    <>
                        {!!row?.original?.end_time ? moment(row?.original?.end_time).format('LT') : "-"}
                    </>
                )
            }
        },
        {
            accessorKey: 'time_taken', //normal accessorKey
            header: 'Time Taken',
            size: 100,
            Cell: ({ row }) => {
                return (
                    <>
                        {!!(row?.original?.time_taken) ? `${row?.original?.time_taken} min` : "-"}
                    </>
                )
            }
        },
    ], [],
    );

    const tableView = useMaterialReactTable({
        columns,
        data: testDrive,
        enableDensityToggle: false,
        enableColumnActions: false,
        enableRowNumbers: true,
        enableFullScreenToggle: false,
        rowNumberDisplayMode: "static",
        muiTableBodyCellProps: {
            style: styles.tableBodyCell
        },
        muiTableHeadCellProps: {
            style: styles.tableHeadCell
        },
        initialState: { pagination: { pageSize: 10, pageIndex: 0 }, density: "compact" },
    });

    return (
        <div >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography style={customStyles.boldText}>Test Drive</Typography>
                </Grid>
                <Grid item xs={12}>
                    <MaterialReactTable table={tableView} />
                </Grid>
            </Grid>
        </div>
    )
}