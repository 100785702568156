import API from '../../api/accountApi';
import {getDashboardData} from '../reducer/dashboardReducer';

/**
 * Fetch dashboard data
 * @function
 * @param {Object} data - The data to send with the request for fetching dashboard data
 * @param {Function} setSeries - A function to update the series data for charts or graphs
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchDashboardData(data, setSeries) {
    return async (dispatch) => {
        API.post('dasboard-widgets', data).then((response) => {
            if (response.status === 'success') {
                dispatch(getDashboardData(response.payload));
                setSeries && setSeries(response.payload.TDMB)
            }
        })
    }
}