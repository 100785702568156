import * as React from 'react';
import { Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Chip, IconButton, FormHelperText, Tooltip, FormControlLabel, RadioGroup, Radio, FormControl, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { addScreen, fetchAllScreen, editScreen } from '../../store/actions/screenAction';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { customStyles, fontFamily } from '../../helpers/customStyles';
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import CustomPermission from '../../components/CustomPermission';
import TvRoundedIcon from '@mui/icons-material/TvRounded';
import palette from '../../theme/palette';

const styles = {
    tableBodyCell: {
        fontFamily: fontFamily.regularFont,
        fontSize: '14px',
        color: '#000',
        height: 60
    },
    tableHeadCell: {
        textAlign: "center",
        backgroundColor: palette.listTable.main,
        color: palette.listTable.color,
        fontFamily: fontFamily.boldFont
    },
    helperText: {
        color: "red",
        marginLeft: "10px"
    },
    statusDesign: {
        display: 'flex',
        flexDirection: 'row'
    },
    cancelButton: {
        position: 'absolute',
        right: 8,
        top: 8,
        color: 'common.black'
    },
    submitButton: {
        '&.MuiButton-root:hover': {
            bgcolor: palette.primary
        }
    }
}

export default function Screens() {
    const [name, setName] = React.useState("");
    const [nameError, setNameError] = React.useState(false);
    const [code, setCode] = React.useState("");
    const [codeError, setCodeError] = React.useState(false);
    const [description, setDescription] = React.useState("");
    const [status, setStatus] = React.useState("ACTIVE");
    const [isOpen, setIsOpen] = React.useState(false);
    const [mode, setMode] = React.useState("ADD");
    const [id, setId] = React.useState();
    const dispatch = useDispatch();

    const screens = useSelector((state) => state.screen.screens);

    React.useEffect(() => {
        dispatch(fetchAllScreen());
    }, [dispatch]);

    const columns = React.useMemo(() => [
        {
            accessorKey: 'name', //access nested data with dot notation
            header: 'Screen Name',
            size: 150,
        },
        {
            accessorKey: 'code',
            header: 'Code',
            size: 150,
        },
        {
            accessorKey: 'description', //normal accessorKey
            header: 'Description',
            size: 200,
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            Cell: ({ row }) => (
                <>
                    {
                        !!row.original.status && row.original.status === "ACTIVE" ? <Chip size="small" label="Active" style={customStyles.activeChip} /> : <Chip color="primary" size="small" label="Inactive" style={customStyles.inActiveChip} />
                    }
                </>
            )
        },
        {
            accessorKey: 'action',
            header: 'Action',
            size: 150,
            Cell: (rowData) => (
                <>
                    <Grid container >
                        <Grid style={{ marginRight: 10 }}>
                            <CustomPermission screenName={'Screens'} feature={'edit'}>
                                <IconButton>
                                    <Tooltip title={'Update Screen'}>
                                        <ModeEditIcon onClick={() => handleDialogOpen(rowData.row.original)} />
                                    </Tooltip>
                                </IconButton>
                            </CustomPermission>
                        </Grid>
                    </Grid>
                </>

            )
        },
    ], [],
    );

    const tableView = useMaterialReactTable({
        columns,
        data: screens,
        enableColumnActions: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableRowNumbers: true,
        rowNumberDisplayMode: "static",
        muiTableBodyCellProps: {
            style: styles.tableBodyCell
        },
        muiTableHeadCellProps: {
            style: styles.tableHeadCell
        },
        initialState: { pagination: { pageSize: 10, pageIndex: 0 }, density: "compact" },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box>
                < CustomPermission screenName={'Screens'} feature={'add'}>
                    <Button size='small' startIcon={<TvRoundedIcon />} variant='contained' color="primary" style={customStyles.buttonText} onClick={() => handleDialogOpen()} sx={{ '&.MuiButton-root:hover': { bgcolor: "#2F7FFF" } }}>Add Screen</Button>
                </CustomPermission>
            </Box>
        )
    });

    const handleDialogClose = () => {
        setIsOpen(false);
    };

    const handleNameChange = (e) => {
        setName(e.target.value.trimStart());
        setNameError(false);
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value.toUpperCase().trim());
        setCodeError(false);
    };

    const handleDialogOpen = (param) => {
        setIsOpen(true);
        if (param) {
            setName(param.name);
            setCode(param.code);
            setDescription(param.description);
            setStatus(param.status);
            setId(param.id);
            setMode("EDIT");
            setNameError(false);
            setCodeError(false);
        } else {
            setName("");
            setNameError(false);
            setCode("");
            setCodeError(false);
            setDescription("");
            setStatus("ACTIVE");
            setId("");
            setMode("ADD")
        }
    };

    const handleSubmit = () => {
        let isError = false;
        let data = {};
        data.name = name;
        data.code = code;
        data.description = description;
        data.status = status;
        data.id = id;
        if (name === null || name === '' || name === undefined) {
            setNameError(true);
            isError = true;
        }
        if (code === null || code === '' || code === undefined) {
            setCodeError(true);
            isError = true;
        }

        if (isError === false) {
            if (mode === 'EDIT') {
                dispatch(editScreen(data, id, setIsOpen))
            } else {
                dispatch(addScreen(data, setIsOpen));
            }
        }
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                    <Typography style={customStyles.boldText}>Screens</Typography>
                </Grid>
                <Dialog open={isOpen} onClose={() => handleDialogClose()} aria-labelledby="customized-dialog-title"
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle id="customized-dialog-title">
                        <Typography style={customStyles.boldText}>{mode === 'EDIT' ? 'Edit Screen' : 'Add Screen'}</Typography>
                    </DialogTitle>
                    <IconButton
                        onClick={() => handleDialogClose()}
                        sx={styles.cancelButton}
                    >
                        <DisabledByDefaultRoundedIcon sx={{ width: 30, height: 30 }} />
                    </IconButton>
                    <DialogContent dividers>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField value={name} label='Name' size='small' placeholder='Name' error={nameError} onChange={handleNameChange} />
                                        {nameError === true && (<FormHelperText style={styles.helperText}>Please enter the screen name</FormHelperText>)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField value={code} size='small' label='Code' placeholder='Code' error={codeError} onChange={handleCodeChange} />
                                        {codeError === true && (<FormHelperText style={styles.helperText}>Please enter the screen code</FormHelperText>)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField value={description} size='small' label='Description' placeholder='Description' onChange={(e) => setDescription(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <Typography style={customStyles.regularText}>Status</Typography>
                                            <RadioGroup
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                                style={styles.statusDesign}
                                            >
                                                <FormControlLabel value="ACTIVE" control={<Radio />} label="Active" />
                                                <FormControlLabel value="INACTIVE" control={<Radio />} label="Inactive" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus variant='contained' color='error' style={customStyles.cancelButtonText} onClick={() => handleDialogClose()}>Close</Button>
                        <Button autoFocus variant="contained" color="primary" onClick={() => handleSubmit()} sx={styles.submitButton}>Submit</Button>
                    </DialogActions>
                </Dialog>
                <Grid item xs={12} marginTop={1}>
                    <MaterialReactTable table={tableView} />
                </Grid>
            </Grid>
        </div>
    )
}