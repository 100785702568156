import { Route, Routes } from 'react-router-dom';
import Login from '../screens/Login/index';
import Dashboard from '../screens/Dashboard';
import AdminLayout from '../layouts/AdminLayout';
import TestDrive from '../screens/TestDrive';
import Users from '../screens/Users';
import Roles from '../screens/Setting/Roles';
import Screens from '../screens/Setting/Screens';
import ForgotPassword from '../screens/ForgotPassword';
import ConfirmPassword from '../screens/ConfirmPassword';
import Clients from '../screens/Clients/index';
import GenerateCode from '../screens/GenerateCode';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import TermsAndConditions from '../screens/TermsAndConditions';
import About from '../screens/About';

function Root() {
    return (
        <Routes>
            {/* Routes for authentication and password management */}
            <Route path='/'>
                <Route index element={<Login />} />
                <Route index path='/forgot-password' element={<ForgotPassword />} />
                <Route index path='/confirm-password' element={<ConfirmPassword />} />
                <Route index path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route index path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route index path='/about' element={<About />} />
            </Route>
            {/* Routes for authenticated users with AdminLayout */}
            <Route element={<AdminLayout />} >
                <Route index path='/dashboard' element={<Dashboard />} />
                <Route path='/test-drives' element={<TestDrive />} />
                <Route path='/users' element={<Users />} />
                <Route path='/roles' element={<Roles />} />
                <Route path='/screens' element={<Screens />} />
                <Route path='/clients' element={<Clients />} />
                <Route path='/generate' element={<GenerateCode />} />
            </Route>
            {/* Catch-all route for unmatched paths */}
            <Route path="*" element={<div>No Match</div>} />
        </Routes>
    );
}

export default Root;