import { useState } from 'react';
import { TextField, Grid, Button, Typography, FormHelperText } from '@mui/material';
import { useDispatch } from 'react-redux';
import { verificationCode } from '../../store/actions/sessionAction';
import backImage from '../../assets/backImageLogin.jpg';
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {

    const [state, setState] = useState({
        userName: "",
        userNameError: false,
        verificationCode: "",
        verificationCodeError: false,
        validationError: false
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = () => {
        let { userName } = state;
        let isError = false;
        if (userName === '' || userName === null || userName === undefined) {
            setState((prevState) => ({ ...prevState, userNameError: true }));
            isError = true;
        }
        if (isError === false) {
            let data = { user_name: userName };
            dispatch(verificationCode(data, navigate));
        }
    }

    const styles = {
        container: {
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            position: 'absolute',
            top: 0
        },
        backgroundDesign: {
            borderRadius: '4%',
            background: 'rgba( 255, 255, 255, 0.25 )',
            boxshadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
            backdropFilter: 'blur( 4px )',
            border: '1px solid rgba( 255, 255, 255, 0.18 )',
        },
        textAlignment: {
            color: 'white',
            textAlign: 'center',
            marginTop: '5%',
            fontWeight: 'bold',
            fontSize: '18px'
        },
        buttonStyle: {
            padding: "8px 70px",
            borderRadius: '16px',
            fontWeight: 'bold',
            background: 'linear-gradient(to right, #183883, #E62D38)'
        },
        buttonContainer:{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'center', 
            marginBottom: 20 
        }
    }

    return (
        <>
            <div style={{
                // backgroundColor: '#FF3CAC',
                backgroundImage: `url(${backImage})`,
                height: '100vh', backgroundSize: 'cover', filter: 'blur(0px)'
            }}>

            </div>
            <Grid container style={styles.container}>
                <Grid item xs={10} sm={6} md={4} lg={3} style={styles.backgroundDesign}>
                    <Typography style={styles.textAlignment}>ForgotPassword</Typography>
                    <Grid container rowGap={3} justifyContent={'center'} style={{ marginTop: '10%', }}>
                        <Grid item xs={8} style={{ marginBottom: '5%' }}>
                            <TextField
                                inputProps={{
                                    style: {
                                        color: '#fff',
                                    }
                                }} placeholder="Username" color='secondary' autoComplete='off' variant='outlined' fullWidth focused value={state.userName} label="Username" size={'medium'} onChange={(e) => setState({ ...state, userName: e.target.value.trim(), userNameError: false, validationError: false })} />
                            {state.userNameError &&
                                <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Please enter the username</FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={8} style={styles.buttonContainer}>
                            <Button style={styles.buttonStyle} type="submit" variant='contained' onClick={() => handleSubmit()}>confirm</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}