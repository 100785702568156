const palette = {
    primary: {
        main: '#2F7FFF',
        // light: '#F5F5F5',
        // dark: "#D53829"
    },
    secondary: {
        main: '#ffff',
        light: '#86BB63',
    },
    success: {
        main: '#059212',
    },
    error:{
        main: '#FF0000'
    },
    chipColor: {
        main: '#32CD67',
        backgroundColor: '#EAFFF1'
    },
    InActiveChipColor: {
        main: '#F8285A',
        backgroundColor: '#FFEEF3'
    },
    listTable: {
        main: '#1976D2',
        color: '#ffff',
        cellColor: '#000'
    }
};
export default palette;