import React from 'react';
import { useSelector } from 'react-redux';

// Component to conditionally render children based on user permissions
const CustomPermission = ({ children, ...props }) => {
    let { feature, screenName, roleCode } = props;
    let userData = useSelector((state) => state.session.user);
    let permission = userData?.role?.permission.find((item) => item.screen === screenName);

    // Function to display the screen if the logged-in user's role code is 'DVP'
    const disPlayScreen = () => {
        let logedInRoleCode = userData.role?.code;
        if (logedInRoleCode === 'DVP') {
            return children
        }
        else {
            return null
        }
    }

    return (
        <div style={{
        }}>{roleCode === 'DVP' ? (disPlayScreen()) : (!!permission && permission[feature] && children)}</div>
    )
}

export default CustomPermission