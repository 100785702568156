
import { createSlice } from "@reduxjs/toolkit";

// Initial state for the user slice
const initialState = {
  users: [],
  activeUsers: [],
  rejectedUsers: [],
  requestedUser: [],
  userCount: {
    activeUsers: 0,
    requestedUsers: 0,
    rejectedUsers: 0
  }
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /**
   * Fetch users and update the state
   * @param {Object} state - The current state
   * @param {Object} action - The action object containing payload
   * @param {Array} action.payload - The list of users
   */
    fetchUsers: (state, action) => {
      state.users = action.payload;
    },
    /**
    * Get active users and update the state
    * @param {Object} state - The current state
    * @param {Object} action - The action object containing payload
    * @param {Array} action.payload - The list of active users
    */
    getActiveUsers: (state, action) => {
      state.activeUsers = action.payload;
    },
    /**
     * Get requested users and update the state
     * @param {Object} state - The current state
     * @param {Object} action - The action object containing payload
     * @param {Array} action.payload - The list of requested users
     */
    getRequestedUsers: (state, action) => {
      state.requestedUser = action.payload
    },
    /**
     * Get rejected users and update the state
     * @param {Object} state - The current state
     * @param {Object} action - The action object containing payload
     * @param {Array} action.payload - The list of rejected users
     */
    getRejectedUsers: (state, action) => {
      state.rejectedUsers = action.payload;
    },
    /**
     * Add a new user and update the state
     * @param {Object} state - The current state
     * @param {Object} action - The action object containing payload
     * @param {Object} action.payload - The new user to be added
     */
    addUser: (state, action) => {
      state.activeUsers = [action.payload, ...state.activeUsers]
    },
    /**
     * Update an existing user and modify the state
     * @param {Object} state - The current state
     * @param {Object} action - The action object containing payload
     * @param {Object} action.payload - The updated user
     */
    updateUser: (state, action) => {
      state.activeUsers = state.activeUsers.map((item) => item.id === action.payload.id ? action.payload : item)
    },
    /**
    * Fetch user metrics and update the state
    * @param {Object} state - The current state
    * @param {Object} action - The action object containing payload
    * @param {Object} action.payload - The user metrics
    * @param {number} action.payload.active_users_count - The count of active users
    * @param {number} action.payload.requested_users_count - The count of requested users
    * @param {number} action.payload.rejected_users_count - The count of rejected users
    */
    fetchUserMetrics: (state, action) => {
      let { active_users_count, requested_users_count, rejected_users_count } = action.payload;
      state.userCount = { ...state.userCount, activeUsers: active_users_count, requestedUsers: requested_users_count, rejectedUsers: rejected_users_count }
    }
  }
});

// Export actions to be used in components
export const { fetchUsers, addUser, updateUser, fetchUserMetrics, getActiveUsers, getRequestedUsers, getRejectedUsers } = userReducer.actions;
// Export reducer to be used in the store
export default userReducer.reducer