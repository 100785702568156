import React, { useState } from 'react';
import Topbar from "./Components/Topbar";
import Sidebar from "./Components/Sidebar";
import { Box } from '@mui/material'
import { Navigate, Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';

const drawerWidth = 250;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(13, 2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#f9fcfe',
    marginLeft: `-0px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


export default function AdminLayout() {
  const token = localStorage !== undefined && localStorage.getItem("accessToken");

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return token !== null ?
    (<Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />
      <Topbar isOpen={open} handleDrawerOpen={() => handleDrawerOpen()} />
      <Sidebar isOpen={open} handleDrawerOpen={() => handleDrawerOpen()} handleDrawerClose={handleDrawerClose} />

      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>)
    :
    (<Navigate to='/' replace />)

}