import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
    Grid, Button, Typography, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, TextField, Radio, RadioGroup,
    FormControlLabel, FormControl, FormLabel, FormGroup, Checkbox, FormHelperText, Chip, Autocomplete, Tooltip, Box
} from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { fetchAllRoles, createAllRoles, updateRole } from "../../store/actions/roleAction";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllScreenForRole } from '../../store/actions/screenAction';
import _ from 'underscore';
import { customStyles, fontFamily } from "../../helpers/customStyles";
import CustomPermission from "../../components/CustomPermission";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import palette from '../../theme/palette';

const styles = {
    errorWarning: {
        color: palette.error.main,
        marginLeft: "5px"
    },
    permissionContainer: {
        marginLeft: '12px',
        marginTop: 30
    },
    permissionScreenText: {
        marginTop: '5px',
        fontFamily: fontFamily.regularFont,
        fontSize: "16px",
        color: 'grey',
        fontWeight: "600",
    },
    viewPermissionText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    viewPermissionContainer: {
        marginLeft: '12px',
        marginTop: 30,
    },
    tableBodyCell: {
        fontFamily: fontFamily.regularFont,
        fontSize: '14px',
        color: '#000'
    },
    tableHeadCell: {
        textAlign: "center",
        backgroundColor: palette.primary.main,
        color: palette.secondary.main,
        fontFamily: fontFamily.boldFont,
    },
    addRoleTextBoxStyle: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row'
    },
    addPermissionTextBoxStyle: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
    },
    addPermissionContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '4%'
    },
    deletePermissionIcon: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    allPermissions: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '4%'
    },
    checkeBoxContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    permissionsListStyle: {
        flexDirection: 'row',
        display: 'flex'
    }
}

export default function Roles() {
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.role.roles);
    const screenReducer = useSelector((state) => state.screen.screens);

    const [state, setState] = useState({
        isDialogueOpen: false,
        roleName: '',
        isInternalRole: true,
        code: '',
        id: '',
        status: 'ACTIVE',
        mode: 'ADD',
        roleNameError: false,
        codeError: false,
        permissionError: false,
        isPermissionDialog: false,
        screenName: [],
        screens: screenReducer,
        isScreenError: false,
        existPermission: []
    });

    const [permission, setPermission] = useState([{ 'id': null, 'screen': "", 'list': false, 'add': false, 'edit': false, 'delete': true }]);

    useEffect(() => {
        dispatch(fetchAllRoles());
        dispatch(fetchAllScreenForRole());
    }, [dispatch]);

    const handleClose = () => {
        setState({ ...state, isDialogueOpen: false });
    };

    const handleChange = (e) => {
        setState({ ...state, status: e.target.value });
    };

    const handleClickInternalRole = () => {
        setState({ ...state, isInternalRole: !state.isInternalRole })
    };

    const handleAddPermission = async () => {
        let findNewPermissionObj = permission.filter(x => x.id === null);
        if (permission.length === 0 || findNewPermissionObj.length === 0) {
            filterArrayFunction()
            let obj = {};
            obj.id = null;
            obj.screen = '';
            obj.list = false;
            obj.add = false;
            obj.edit = false;
            obj.delete = false;
            setPermission([...permission, obj]);
        } else {
            setState((prevState) => ({
                ...prevState, isScreenError: true
            }))
        }
    };

    const handleAdd = () => {
        filterArrayFunction();
        setState({ ...state, existPermission: [], isScreenError: false, isDialogueOpen: true, roleName: '', code: '', roleNameError: false, codeError: false, status: 'ACTIVE', isInternalRole: true, mode: 'ADD', screens: screenReducer });
        setPermission([{ id: null, screen: '', add: false, edit: false, delete: false, list: false }])
    };

    const filterArrayFunction = useCallback(() => {
        let filteredArray = [];
        let permissionName = _.pluck(permission, 'screen');
        for (let x of screenReducer) {
            if (!permissionName.includes(x.name)) {
                filteredArray = [...filteredArray, x];
            }
        }
        setState((prevState) => ({ ...prevState, screens: filteredArray }));
        return filteredArray;
    }, [permission, screenReducer]);

    const deletePermission = (ele) => {
        setPermission(permission.filter((item) => item.id !== ele.id));
        let existScreen = screenReducer.filter((scr) => scr.id === ele.id);
        let removePermission = state.existPermission.filter(x => x !== ele.id);
        let screenData = [...state.screens, ...existScreen];
        setState({ ...state, screens: screenData, existPermission: removePermission });
        filterArrayFunction();
    };

    const handleEdit = useCallback((rowData) => {
        setPermission(rowData.permission);
        filterArrayFunction();
        setState({
            ...state,
            isDialogueOpen: true,
            mode: 'EDIT',
            id: rowData.id,
            roleName: rowData.name,
            roleNameError: false,
            code: rowData.code,
            codeError: false,
            status: rowData.status,
            isInternalRole: rowData.is_internal_role,
            permissionError: false,
            existPermission: _.pluck(rowData.permission, 'id')
        });
    }, [state, setPermission, filterArrayFunction, setState]);

    const showPermissions = useCallback((data) => {
        setState({ ...state, isPermissionDialog: true });
        setPermission(data);
    }, [state, setState, setPermission]);


    const handleSubmit = () => {
        let { id, roleName, code, status, isInternalRole, mode } = state;
        let isError = false;

        if (roleName === '' || roleName === undefined || roleName === null) {
            setState((prevState) => ({ ...prevState, roleNameError: true }));
            isError = true;
        }
        if (code === '' || code === undefined || code === null) {
            setState((prevState) => ({ ...prevState, codeError: true }));
            isError = true;
        }

        let findInvalidPermission = permission.filter(x => x.id === null);

        if (findInvalidPermission.length !== 0) {
            isError = true;
            setState((prevState) => ({
                ...prevState, isScreenError: true
            }));
        }

        if (isError === false) {
            let obj = {};
            obj.name = roleName;
            obj.code = code;
            obj.permission = permission;
            obj.is_internal_role = isInternalRole;
            obj.status = status;
            if (mode === 'ADD') {
                dispatch(createAllRoles(obj, state, setState));
            } else {
                obj.id = id;
                dispatch(updateRole(obj, state, setState));
            }
        }
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Role Name',
                grow: false,
                minSize: 50,
                muiTableHeadCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'code',
                header: 'Code',
                grow: false,
                minSize: 50,
            },
            {
                accessorKey: 'is_internal_role',
                header: 'Is Internal Role',
                minSize: 50,
                grow: false,
                muiTableHeadCellProps: {
                    align: 'left',
                },
                Cell: ({ row }) => (
                    <>
                        {
                            !!row.original.is_internal_role && row.original.is_internal_role === true ? <Chip size="small" label="Internal" style={customStyles.adminChip} /> : <Chip size="small" label="Client" style={customStyles.clientChip} />
                        }
                    </>
                )
            },
            {
                accessorKey: 'status',
                header: 'Status',
                grow: false,
                minSize: 50,
                Cell: ({ row }) => (
                    <>
                        {
                            !!row.original.status && row.original.status === "ACTIVE" ? <Chip size="small" label="Active" style={customStyles.activeChip} /> : <Chip color="primary" size="small" label="Inactive" style={customStyles.inActiveChip} />
                        }
                    </>
                )
            },
            {
                accessorKey: 'action',
                header: 'Action',
                minSize: 50,
                grow: false,
                Cell: (rowData) => (
                    <>
                        <Grid container >
                            <Grid item xs={12} style={{ marginRight: 10 }}>
                                <CustomPermission screenName={'Roles'} feature={'edit'}>
                                    <IconButton>
                                        <Tooltip title={'Update Role'}>
                                            <ModeEditIcon onClick={() => handleEdit(rowData.row.original)} />
                                        </Tooltip>
                                    </IconButton>
                                    <Button onClick={() => showPermissions(rowData.row.original.permission)}>
                                        <ListAltRoundedIcon color="action" />
                                    </Button>
                                </CustomPermission>
                            </Grid>
                        </Grid>
                    </>
                )
            },
        ],
        [handleEdit, showPermissions],
    );

    const tableView = useMaterialReactTable({
        columns,
        data: roles,
        enableColumnActions: false,
        enableRowNumbers: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        rowNumberDisplayMode: "static",
        muiTableBodyCellProps: {
            style: styles.tableBodyCell
        },
        muiTableHeadCellProps: {
            style: styles.tableHeadCell
        },
        initialState: { pagination: { pageSize: 10, pageIndex: 0 }, density: "compact" },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box>
                <CustomPermission screenName={'Roles'} feature={'add'}>
                    <Button startIcon={<AdminPanelSettingsRoundedIcon />} size={"small"} sx={customStyles.buttonText} variant="contained" color="primary" onClick={() => handleAdd()}>
                        Add Role
                    </Button>
                </CustomPermission>
            </Box>
        )
    });

    const handleChangeCheckBox = (e, ele, index) => {
        let obj = { ...ele };
        obj.id = ele.id;
        obj.screen = ele.screen;
        obj.list = e.target.name === 'list' ? e.target.checked : ele.list;
        obj.add = e.target.name === 'add' ? e.target.checked : ele.add;
        obj.edit = e.target.name === 'edit' ? e.target.checked : ele.edit;
        obj.delete = e.target.name === 'delete' ? e.target.checked : ele.delete;
        const result = permission.map((x) => (x.id === ele.id ? obj : x));
        setPermission([...result]);
    };

    const handleClosePermissionDialog = () => {
        setState({ ...state, isPermissionDialog: false });
    };

    const autoCompleteChange = (value, id) => {
        const currentIndex = permission.findIndex((item) => item.id === id);
        let updatedScreens = state.screens.filter((item) => item.name !== value.name);
        let obj = {};
        obj.id = value.id;
        obj.screen = value.name;
        setState({ ...state, isScreenError: false, screens: updatedScreens, screenName: [...state.screenName, obj] });
        let dummyObj = permission[currentIndex];
        dummyObj.id = value.id;
        dummyObj.screen = value.name;
        dummyObj.list = true;
        permission[currentIndex] = dummyObj;
        filterArrayFunction();
    };

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container >
                        <Grid item xs={6}>
                            <Grid container >
                                <Typography style={customStyles.boldText}>
                                    Roles
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <MaterialReactTable table={tableView} />
                        </Grid>
                        <Dialog open={state.isDialogueOpen} maxWidth={'md'}>
                            <DialogTitle style={{ display: 'flex' }} justifyContent={'space-between'} alignItems={'center'}>
                                {state.mode === "ADD" ? <Typography style={customStyles.boldText}>Add Role</Typography> : <Typography style={customStyles.boldText}>Edit Role</Typography>}
                                <IconButton onClick={() => handleClose()} style={{ color: '#000' }}>
                                    <DisabledByDefaultRoundedIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={styles.addRoleTextBoxStyle}>
                                        <Grid item xs={4} style={{ marginLeft: '4%' }}>
                                            <TextField
                                                label="Role Name"
                                                placeholder="Role Name"
                                                fullWidth
                                                size='small'
                                                // multiline
                                                value={state.roleName}
                                                onChange={(event) => setState({ ...state, roleName: event.target.value.trimStart(), roleNameError: false })}
                                                error={state.roleNameError}
                                            />
                                            {state.roleNameError &&
                                                <FormHelperText style={styles.errorWarning}>Please enter the Role Name</FormHelperText>
                                            }
                                        </Grid>
                                        <Grid item xs={3} style={{ marginLeft: '6%' }} >
                                            <FormControlLabel
                                                label="Is Internal Role"
                                                control={
                                                    <Checkbox
                                                        defaultChecked={state.isInternalRole}
                                                        value={state.isInternalRole}
                                                        onChange={() => handleClickInternalRole()}
                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={styles.addPermissionTextBoxStyle}>
                                        <Grid item xs={4} style={{ marginLeft: '4%' }}>
                                            <TextField
                                                label="Code"
                                                placeholder="Code"
                                                fullWidth
                                                size='small'
                                                // multiline
                                                value={state.code}
                                                error={state.codeError}
                                                onChange={(event) => setState({ ...state, code: event.target.value.toUpperCase().trim(), codeError: false })}
                                            />
                                            {state.codeError &&
                                                <FormHelperText style={styles.errorWarning}>Please enter the Code</FormHelperText>
                                            }
                                        </Grid>
                                        <Grid item xs={4} style={{ marginLeft: '6%' }}>
                                            <FormControl>
                                                <FormLabel style={customStyles.regularText}>Status</FormLabel>
                                                <RadioGroup
                                                    value={state.status}
                                                    defaultValue={state.status}
                                                    onChange={(e) => handleChange(e)}
                                                    row
                                                >
                                                    <FormControlLabel value="ACTIVE" control={<Radio />} label="Active" />
                                                    <FormControlLabel value="INACTIVE" control={<Radio />} label="Inactive" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} style={styles.addPermissionContainer}>
                                        <Typography style={styles.permissionScreenText}>Permission Screens :</Typography>
                                        <Button onClick={() => handleAddPermission()}>
                                            <AddCircleRoundedIcon color="primary" />
                                        </Button>
                                    </Grid>
                                    <Grid container rowGap={3} style={styles.permissionContainer}>
                                        {!!permission && permission.length > 0 && permission.map((ele, index) => (
                                            <Grid item xs={12} key={index} style={styles.allPermissions}>
                                                <Grid item xs={4}>
                                                    <FormControl sx={{ minWidth: 140 }} fullWidth >
                                                        <Autocomplete
                                                            options={state.screens}
                                                            filterOptions={(options) => options}
                                                            size="small"
                                                            disableClearable
                                                            disabled={state.existPermission.includes(ele.id)}
                                                            fullWidth
                                                            getOptionLabel={(option) => option.name.toString()}
                                                            filterSelectedOptions
                                                            inputValue={ele.screen !== null ? ele.screen.toString() : ""}
                                                            onChange={(event, newValue) =>
                                                                autoCompleteChange(newValue, ele.id)
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Screens"
                                                                    placeholder="Favorites"
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                    {ele.id === null && state.isScreenError &&
                                                        <FormHelperText style={styles.errorWarning}>Please Select Screen</FormHelperText>
                                                    }
                                                </Grid>
                                                <Grid item xs={6} style={styles.checkeBoxContainer}>
                                                    <FormGroup row>
                                                        <FormControlLabel control={<Checkbox name="list" disabled={ele.id === null ? true : false} checked={ele.list} />} label="List" />
                                                        <FormControlLabel control={<Checkbox name="add" disabled={ele.id === null ? true : false} checked={ele.add} onChange={(event) => handleChangeCheckBox(event, ele, index)} />} label="Add" />
                                                        <FormControlLabel control={<Checkbox name="edit" disabled={ele.id === null ? true : false} checked={ele.edit} onChange={(event) => handleChangeCheckBox(event, ele)} />} label="Edit" />
                                                        <FormControlLabel control={<Checkbox name="delete" disabled={ele.id === null ? true : false} checked={ele.delete} onChange={(event) => handleChangeCheckBox(event, ele, index)} />} label="Delete" />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={1} style={styles.deletePermissionIcon}>
                                                    <Button onClick={() => deletePermission(ele)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </Grid>
                                            </Grid>))}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus variant='contained' color='error' onClick={() => handleClose()} sx={customStyles.cancelButtonText}>Cancel</Button>
                                <Button variant="contained" sx={customStyles.buttonText} color="primary" onClick={() => handleSubmit()}> {state.mode === "ADD" ? "Submit" : "Update"}</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog onClose={() => handleClosePermissionDialog()} open={state.isPermissionDialog} fullWidth maxWidth={'md'} >
                            <DialogTitle style={{ display: 'flex' }} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography style={customStyles.boldText}>Permission</Typography>
                                <IconButton onClick={() => handleClosePermissionDialog()} sx={{ color: '#000' }}>
                                    <DisabledByDefaultRoundedIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid container rowGap={3} style={styles.viewPermissionContainer}>
                                        {!!permission && permission.length > 0 ? (
                                            permission.map((ele, index) => (
                                                <Grid item xs={12} key={index} style={styles.viewPermissionText}>
                                                    <Grid item xs={3}>
                                                        <Typography style={{ textAlign: 'right' }}>{ele.screen}</Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>:</Grid>
                                                    <Grid item xs={7} style={{ justifyContent: 'center' }}>
                                                        <Grid container>
                                                            <Grid item xs={12} columnGap={2} style={styles.permissionsListStyle}>
                                                                <Typography>List</Typography>
                                                                <Typography>{ele.list ? <CheckCircleOutlineRoundedIcon color="success" /> : <HighlightOffRoundedIcon color="error" />}</Typography>
                                                                <Typography>Add</Typography>
                                                                <Typography>{ele.add ? <CheckCircleOutlineRoundedIcon color="success" /> : <HighlightOffRoundedIcon color="error" />}</Typography>
                                                                <Typography>Edit</Typography>
                                                                <Typography>{ele.edit ? <CheckCircleOutlineRoundedIcon color="success" /> : <HighlightOffRoundedIcon color="error" />}</Typography>
                                                                <Typography>Delete</Typography>
                                                                <Typography>{ele.delete ? <CheckCircleOutlineRoundedIcon color="success" /> : <HighlightOffRoundedIcon color="error" />}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography>No permissions available.</Typography>
                                        )}

                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                {/* <Button variant="outlined" onClick={() => handleClosePermissionDialog()}>Close</Button> */}
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}