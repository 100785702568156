import API from "../../api/accountApi";
import { fetchClients } from '../reducer/clientReducer';

/**
 * Fetch all clients 
 * @function
 * @param {string} data - The code used to filter or fetch client data
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function fetchAllClients(data) {
    return async (dispatch) => {
        API.get(`user/client?code=${data}`).then((response) => {
            if (response.status === 'success') {
                dispatch(fetchClients(response.payload));
            }
        });
    }
}